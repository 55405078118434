import React from "react";
// Customizable Area Start
import { Divider, Grid, Box, Typography, Badge, Avatar, Container } from "@material-ui/core";
import { t } from "i18next";
import CreateOutlined from '@material-ui/icons/CreateOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { user4 } from '../assets'
import UserSkills from "./UserSkills.web";
interface UserProfileInterface {
    user: any
    navigation?: any
}
interface BasicDetailsDataInterface {
    leftText: string
    rightText: string
}
interface UserInfoInterface {
    text: string
    style: Object
}

const UserAboutMe = (props: UserProfileInterface) => {
    const { user, navigation } = props
    const renderBasicDetails = (basicDetailsData: BasicDetailsDataInterface) => {
        return (
            <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0' }}>
                <Grid className="text_first_tb_grid" item xs={6} sm={6}>
                    <Typography className="text_first_tb">{t(basicDetailsData.leftText)}</Typography>
                </Grid>
                <Grid container className="text_second_tb_grid" item xs={6} sm={6}>
                    <Typography className="text_first_tb">{basicDetailsData.rightText}</Typography>
                </Grid>
            </Grid>
        )
    }

    const renderUserInfo = (info: UserInfoInterface) => {
        return (
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} container style={{ justifyContent: 'center' }}>
                <Typography
                    variant="subtitle2"
                    style={info.style}
                >{info.text}</Typography>
            </Grid>
        )
    }

    let filteredConnections = ''
    if (!!user && !!user.connections && user.connections.length > 0) {
        filteredConnections = user.connections.map((connection: { name: string; }) => connection.name).join(", ");
    }

    const [isExpanded, setIsExpanded] = React.useState(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const aboutText = user?.about_yourself || ''

    return (
        <Box>
            <Box>
                <Grid container className="user_about_me_container">
                    <Grid item container xs={2}></Grid>
                    <Grid item container xs={8} style={{ justifyContent: 'center' }}>
                        <Avatar
                            src={user?.photo}
                            alt="User Profile Picture"
                            style={{
                                width: 120,
                                height: 120,
                            }}
                        />
                    </Grid>
                    <Grid
                        item xs={2}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography
                                variant="subtitle2"
                                className="edit_profile_button"
                                onClick={() => navigation.navigate("Settings5")}
                            >{t("Edit Profile")}</Typography>
                            <Badge style={{ cursor: "pointer" }} data-test-id='settings5_click' onClick={() => navigation.navigate("Settings5")}>
                                <CreateOutlined
                                    style={{
                                        color: '#64748B',
                                        fontSize: 20
                                    }}></CreateOutlined>
                            </Badge>
                        </div>
                    </Grid>
                </Grid>
                {renderUserInfo({
                    text: user?.full_name, style: {
                        fontFamily: "Nunito-Regular",
                        color: '#475569',
                        fontSize: '20px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        marginTop: '8px',
                        textAlign: 'center',
                        wordBreak: 'break-all'
                    }
                })}
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} container style={{ justifyContent: 'center', marginTop: '16px', }}>
                    <p style={{
                            fontFamily: "Nunito-Regular",
                            color: '#475569',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                            maxWidth: '560px',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}>
                        {isExpanded ? aboutText : `${aboutText.slice(0, 150)}${aboutText.length > 150 ? '...' : ''}`}
                        {aboutText.length > 150 && <span data-test-id= "span-read-more" onClick={toggleExpand} style={{
                                fontFamily: "Nunito-Regular",
                                color: '#5497FF',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                cursor: 'pointer',
                                alignSelf: 'flex-end'
                            }}>
                            {isExpanded ?  (<> Show less</>) : (<> Show more</>)}
                        </span>}
                    </p>
                </Grid>
            </Box>
            <Box style={{ marginTop: '16px' }}>
                <Container disableGutters style={{ marginTop: '40px' }}>
                    <Divider className="show_border"></Divider>
                    {renderBasicDetails({ leftText: 'Email Address', rightText: user?.email })}
                    {renderBasicDetails({ leftText: 'Mobile Number', rightText: user?.full_phone_number })}
                    <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0', justifyContent: 'flex-end' }}>
                        <Grid xs={6} sm={6} style={{ padding: '20px 0px 20px 0px', alignItems: "center" }}>
                            <Typography className="text_first_tb">{t('Lives in and Time Zone')}</Typography>
                        </Grid>
                        <Grid xs={6} sm={6} className="location_first_value">
                            <Typography className="location1_text_align">{user?.city}, {user?.state}, {user?.country}</Typography>
                            <Typography className="location2_text_align">{user?.time_zone}</Typography>
                        </Grid>
                    </Grid>
                    {renderBasicDetails({ leftText: 'Working language', rightText: user?.working_language })}
                    {renderBasicDetails({ leftText: 'App preferred language', rightText: user?.preferred_language })}
                    {renderBasicDetails({ leftText: 'PBA Connection', rightText: filteredConnections })}
                    {UserSkills({ title: 'Skills', hobby: (!!user.skill_name && user?.skill_name) })}
                    {UserSkills({ title: 'Interests', hobby: (!!user.interest_name && user?.interest_name) })}
                    <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0'}}>
                        <Grid className={"summary_exp_grid_two"}>
                            <Typography className="text_first_tb">{t('Organizational affiliations and Socials')}</Typography>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <div data-test-id='org_html' className="affilation_text" dangerouslySetInnerHTML={{__html: user.organizational_affilation || ''}}></div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid className={"summary_exp_grid_two"}>
                            <Typography className="text_first_tb">{t('Summary of Experience')}</Typography>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <div data-test-id='experience_html' className="affilation_text" dangerouslySetInnerHTML={{__html: user.experience || ''}}></div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};
// Customizable Area End

export default UserAboutMe;

import React from 'react';
import PostDetails from './PostDetails.web';
import HashTagRelated from './HashTagRelated.web';

const MiddleContainer = (
    {
        state,
        authorId,
        playVideo,
        playAudio,
        onLikeHandler,
        onEditHandler,
        onReplyHandler,
        onDeleteHandler,
        onCommentChange,
        onRepliedHandler,
        onCommentHandler,
        handlePostDetails,
        allDownloadHandler,
        onMediaModalHandler,
        onShareInputHandler,
        onClickCommentIndex,
        onClickReplyComment,
        handlePostDetailsBack,
        handleConfirmModalOpen,
        onCommentDeleteHandler,
        onReportModalClickHandler,
        commentBoxVisibilityHandler,
        handleTransBackToOriginal,
        handleTranslation,
        onrhDescriptionChange,
        onrhLanguageChange,
        handleOnShareToResourceHub
    }: any) => {
    return (
        <>
            {
                state.hashtagFiltered.length > 0 &&
                <HashTagRelated
                    state={state}
                    authorId={authorId}
                    playVideo={playVideo}
                    playAudio={playAudio}
                    onLikeHandler={onLikeHandler}
                    onEditHandler={onEditHandler}
                    onReplyHandler={onReplyHandler}
                    onCommentChange={onCommentChange}
                    onDeleteHandler={onDeleteHandler}
                    onCommentHandler={onCommentHandler}
                    onRepliedHandler={onRepliedHandler}
                    handlePostDetails={handlePostDetails}
                    allDownloadHandler={allDownloadHandler}
                    onShareInputHandler={onShareInputHandler}
                    onMediaModalHandler={onMediaModalHandler}
                    onClickReplyComment={onClickReplyComment}
                    onClickCommentIndex={onClickCommentIndex}
                    handlePostDetailsBack={handlePostDetailsBack}
                    onCommentDeleteHandler={onCommentDeleteHandler}
                    handleConfirmModalOpen={handleConfirmModalOpen}
                    onReportModalClickHandler={onReportModalClickHandler}
                    commentBoxVisibilityHandler={commentBoxVisibilityHandler}
                    handleTransBackToOriginal={handleTransBackToOriginal}
                    handleTranslation={handleTranslation}
                    onrhDescriptionChange={onrhDescriptionChange}
                    onrhLanguageChange={onrhLanguageChange}
                    handleOnShareToResourceHub={handleOnShareToResourceHub}
                />
            }
            {
                state.postDetails?.attributes &&
                <PostDetails
                    state={state}
                    authorId={authorId}
                    playAudio={playAudio}
                    playVideo={playVideo}
                    onEditHandler={onEditHandler}
                    onLikeHandler={onLikeHandler}
                    onReplyHandler={onReplyHandler}
                    onDeleteHandler={onDeleteHandler}
                    onCommentChange={onCommentChange}
                    onRepliedHandler={onRepliedHandler}
                    onCommentHandler={onCommentHandler}
                    handlePostDetails={handlePostDetails}
                    allDownloadHandler={allDownloadHandler}
                    onMediaModalHandler={onMediaModalHandler}
                    onShareInputHandler={onShareInputHandler}
                    onClickCommentIndex={onClickCommentIndex}
                    onClickReplyComment={onClickReplyComment}
                    handlePostDetailsBack={handlePostDetailsBack}
                    handleConfirmModalOpen={handleConfirmModalOpen}
                    onCommentDeleteHandler={onCommentDeleteHandler}
                    onReportModalClickHandler={onReportModalClickHandler}
                    commentBoxVisibilityHandler={commentBoxVisibilityHandler}
                    handleTransBackToOriginal={handleTransBackToOriginal}
                    handleTranslation={handleTranslation}
                    onrhDescriptionChange={onrhDescriptionChange}
                    onrhLanguageChange={onrhLanguageChange}
                    handleOnShareToResourceHub={handleOnShareToResourceHub}
                />
            }
        </>
    )
}

export default MiddleContainer
import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  Typography,
  Snackbar,
  MenuItem,
  CircularProgress,
  Box,
  Divider,
  Badge
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Header from "../../../components/src/Header.web";
import { RecentGroups } from "./components/recentGroups.web";
import RightBar from "../../../components/src/RightBar.web";
import "../assets/index.scss";
import { groupIcons, groupIcon, moreHoriz, notification, permMedia, groupCover } from './assets';
import CreateNewGroup from "./components/createNewGroup.web";
import { AttachmentCard } from "./components/attachmentCard.web";
import { ResponsiveRecentGroups } from "./components/responsiveRecentGroups.web";
import { Posts } from "./components/posts.web"
import GroupMembers from "./components/groupMembers.web";
import GroupMedia from "./components/groupMedia.web";
import { Hashtags } from "./components/hashTags.web"
import ViewAllGroups from "./components/viewAllGroups.web"
import ConfirmationBox from "../../../components/src/ConfirmationBox.web";
import MediaSlider from "../../CommunityForum/src/components/MediaSlider.web";
import PostDetails from "../../CommunityForum/src/components/PostDetails.web";
import { CreatePostModal } from "./components/createPostModal.web";
import HashTagRelated from "../../CommunityForum/src/components/HashTagRelated.web";
import { t } from "i18next"
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  groupStatus3 = (item2: any) => {
    if (item2?.status !== "accepted" && item2?.request_type !== "requested") {
      return t("Join Group")
    } else if (item2.status === "pending" && item2.request_type === "requested") {
      return t("Requested")
    } else {
      return t("Joined")
    }
  }

  showIcons = (group: any, isMember: any, status: any) => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }} className="notif-info">
          <>
            <Badge style={{ cursor: 'pointer'}}>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11453_70305)">
                  <path d="M16.1198 20.1976C16.12 20.7164 15.9241 21.2161 15.5713 21.5965C15.2186 21.9769 14.735 22.2099 14.2177 22.2488L14.0635 22.254H12.0072C11.4884 22.2541 10.9887 22.0582 10.6083 21.7054C10.2279 21.3527 9.99487 20.8692 9.95596 20.3518L9.95082 20.1976H16.1198ZM13.0353 2.71875C14.9014 2.71872 16.6946 3.4435 18.0366 4.74021C19.3786 6.03691 20.1644 7.80416 20.2284 9.66917L20.2325 9.91593V13.786L22.1058 17.5326C22.1876 17.6961 22.2285 17.8769 22.2251 18.0597C22.2216 18.2424 22.174 18.4216 22.0861 18.5819C21.9983 18.7422 21.8729 18.8789 21.7208 18.9801C21.5686 19.0814 21.3941 19.1442 21.2124 19.1633L21.0941 19.1695H4.97654C4.79369 19.1695 4.61356 19.1252 4.45157 19.0404C4.28959 18.9556 4.15059 18.8328 4.04647 18.6825C3.94236 18.5322 3.87624 18.3588 3.85378 18.1774C3.83132 17.9959 3.85319 17.8117 3.91752 17.6406L3.96482 17.5326L5.83814 13.786V9.91593C5.83814 8.00712 6.59641 6.17649 7.94615 4.82676C9.29588 3.47702 11.1265 2.71875 13.0353 2.71875Z" fill="#5497FF" />
                </g>
                <defs>
                  <clipPath id="clip0_11453_70305">
                    <rect width="24.6761" height="24.6761" fill="white" transform="translate(0.697266 0.664062)" />
                  </clipPath>
                </defs>
              </svg>
            </Badge>
            <img data-test-id="groupMedia" src={permMedia} 
            // onClick={() => this.handleOpen("GroupMedia")} 
            style={{ cursor: 'pointer', marginLeft: '16px' }} />
            <img data-test-id="groupMembers" src={groupIcon} 
            // onClick={() => this.handleOpen("GroupMembers")} 
            style={{ cursor: 'pointer',marginLeft: '16px' }} />
            <Typography
              className="memberCount">
              {`${group?.attributes?.group_members} ${group?.attributes?.group_members > 1 ? t("members") : t("member")}`}
            </Typography>
          </>
        </div>
      </>
    )
  }

  showPosts = (group: any, hashtags: any, isMember: any, recentGroups: any) => {
    return (
      <>
        {isMember && <AttachmentCard postModalHandlers={this.postModalHandlers} group={group} />}
        {/* <Hashtags hashtags={hashtags} handleHashTags={this.handleHashTags} /> */}
        <Posts posts={this.state.posts} mediaArr={this.mediaArr} commentInput={this.state.commentInput} authorId={this.state.userId}
          onCommentChange={this.onCommentChange} onCommentHandler={this.onCommentHandler} onLikeHandler={this.onLikeHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
          isCommentEnable={this.state.isCommentEnable} commentIndex={this.state.commentIndex} replyIndex={this.state.replyIndex} commentActiveIndex={this.state.commentActiveIndex} isReplied={this.state.isReplied} repiedIndex={this.state.repiedIndex}
          isCommentActive={this.state.isCommentActive} isReportModal={this.state.isReportModal} reportIndex={this.state.reportIndex}
          onRepliedHandler={this.onRepliedHandler} onReplyHandler={this.onReplyHandler} onClickReplyComment={this.onClickReplyComment}
          onClickCommentIndex={this.onClickCommentIndex}
          onReportModalClickHandler={this.onReportModalClickHandler} allDownloadHandler={this.allDownloadHandler}
          handleConfirmModalOpen={this.handleConfirmModalOpen}
          onMediaModalHandler={this.onMediaModalHandler} playVideo={this.playVideo} playAudio={this.playAudio} onShareInputHandler={this.onShareInputHandler}
          handlePostDetails={this.handlePostDetails} fetchData={this.fetchData} totalPages={this.state.totalPages} pageNumber={this.state.pageNumber}
          onDeleteHandler={this.onDeleteHandler} groupId={this.state.group.id} onEditHandler={this.onEditHandler} isMember={this.state.isMember}
          onCommentDeleteHandler={this.onCommentDeleteHandler}
        />
      </>
    )
  }

  showOtherComponents = (openOtherComponent: any, groups: any, group: any, isMember: any, hashtags: any, status: any, confirmModalOpen: any,
    showSnackbar: any, severity: any, message: any, recentGroups: any) => {
    if (this.state.hashtagFiltered.length > 0 || this.state?.postDetails?.attributes) {
      return(
      <>
      {
        this.state.hashtagFiltered.length > 0 &&
        <HashTagRelated
          state={this.state}
          onLikeHandler={this.onLikeHandler}
          onReplyHandler={this.onReplyHandler}
          onCommentChange={this.onCommentChange}
          onCommentHandler={this.onCommentHandler}
          onRepliedHandler={this.onRepliedHandler}
          allDownloadHandler={this.allDownloadHandler}
          onShareInputHandler={this.onShareInputHandler}
          onMediaModalHandler={this.onMediaModalHandler}
          onClickReplyComment={this.onClickReplyComment}
          onClickCommentIndex={this.onClickCommentIndex}
          handlePostDetailsBack={this.handlePostDetailsBack}
          handleConfirmModalOpen={this.handleConfirmModalOpen}
          onReportModalClickHandler={this.onReportModalClickHandler}
          commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
          authorId={this.state.userId}
          playVideo={this.playVideo}
          playAudio={this.playAudio}
        /> 
      }
      {this.state?.postDetails?.attributes && (
        <PostDetails state={this.state} authorId={this.state.userId}
        onClickReplyComment={this.onClickReplyComment} onClickCommentIndex={this.onClickCommentIndex} handlePostDetailsBack={this.handlePostDetailsBack}
        handleConfirmModalOpen={this.handleConfirmModalOpen} onReportModalClickHandler={this.onReportModalClickHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
        playVideo={this.playVideo} playAudio={this.playAudio} onLikeHandler={this.onLikeHandler} onReplyHandler={this.onReplyHandler}
        allDownloadHandler={this.allDownloadHandler} onShareInputHandler={this.onShareInputHandler} onMediaModalHandler={this.onMediaModalHandler}
        onCommentChange={this.onCommentChange} onCommentHandler={this.onCommentHandler} onRepliedHandler={this.onRepliedHandler} />
      )}
      </>
      )
    }
    else if (openOtherComponent === "GroupMedia") {
      return <GroupMedia navigation={""} id={""} handleOpen={this.handleOpen} groupMediaData={{}} group={group}
        groups={groups} handleJoinGroup={this.handleJoinGroup} getGroupDetails={this.getGroupDetails} recentGroups={recentGroups}
        handlePostDetails={this.handlePostDetails}
      />
    }
    else if (openOtherComponent === "GroupMembers") {
      return <GroupMembers navigation={""} id={""} handleOpen={this.handleOpen} group={group}
        groups={groups} handleJoinGroup={this.handleJoinGroup} getGroupDetails={this.getGroupDetails} recentGroups={recentGroups} />
    }
    else {
      return <Container className="group_section">
        <Box style={{ width: '100%' }}>
          <Grid>
            <Badge style={{ cursor: 'pointer' }} data-test-id="back_icon_group" onClick={() => {this.props.navigation.goBack() }}>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6446 11.4735H8.15999L13.1775 6.45604C13.5784 6.05505 13.5784 5.39702 13.1775 4.99604C12.9854 4.80351 12.7246 4.69531 12.4526 4.69531C12.1806 4.69531 11.9198 4.80351 11.7277 4.99604L4.95211 11.7717C4.55112 12.1727 4.55112 12.8204 4.95211 13.2214L11.7277 19.997C12.1287 20.398 12.7765 20.398 13.1775 19.997C13.5784 19.596 13.5784 18.9483 13.1775 18.5473L8.15999 13.5298H19.6446C20.2101 13.5298 20.6728 13.0672 20.6728 12.5017C20.6728 11.9362 20.2101 11.4735 19.6446 11.4735Z" fill="black" />
              </svg>
            </Badge>
          </Grid>
          <Grid item container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={group?.attributes?.icon_image || groupIcons}
              className="group_profile_image"
              style={styles.groupImage}
            />
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'center', marginTop: '8px', alignItems:"center"}}>
            <Typography className="group_Name">
              {group?.attributes?.name}
            </Typography>
            <Badge data-test-id="handleMoreHoriz" style={{ cursor: "pointer", marginLeft:'6px' }} onClick={() => this.handleMoreHoriz()}>
              <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.44403 10.7656C5.31305 10.7656 4.3877 11.691 4.3877 12.822C4.3877 13.9529 5.31305 14.8783 6.44403 14.8783C7.57502 14.8783 8.50037 13.9529 8.50037 12.822C8.50037 11.691 7.57502 10.7656 6.44403 10.7656ZM18.7821 10.7656C17.6511 10.7656 16.7257 11.691 16.7257 12.822C16.7257 13.9529 17.6511 14.8783 18.7821 14.8783C19.913 14.8783 20.8384 13.9529 20.8384 12.822C20.8384 11.691 19.913 10.7656 18.7821 10.7656ZM12.613 10.7656C11.4821 10.7656 10.5567 11.691 10.5567 12.822C10.5567 13.9529 11.4821 14.8783 12.613 14.8783C13.744 14.8783 14.6694 13.9529 14.6694 12.822C14.6694 11.691 13.744 10.7656 12.613 10.7656Z" fill="#64748B" />
              </svg>
              {this.state.isMoreHorizOpen && (
                <Box className='group-report-modal'>
                  {this.state.adminIds.includes(this.state.userId) && (
                    <>
                      <Typography data-test-id="handleEditGroup" className='report-text' onClick={this.handleEditGroup}>{t('Edit Group')}</Typography>
                    </>
                  )}
                  {!this.state.adminIds.includes(this.state.userId) && (
                    <>
                      <Typography data-test-id="handleReportGroup" className='report-text' onClick={this.handleReportGroup}>{t('Report Group')}</Typography>
                    </>
                  )}
                  {this.state.adminIds.length !== 1 && (
                    <>
                      <Divider style={{ width: "100%" }} />
                      <Typography data-test-id="handleLeaveGroup" className='report-text' onClick={this.handleLeaveGroup}>{t("Leave Group")}</Typography>
                    </>
                  )}
                  {this.state.adminIds.includes(this.state.userId) && (
                    <>
                      <Divider style={{ width: "100%" }} />
                      <Typography data-test-id="handleDeleteGroup" className='report-text' onClick={this.handleDeleteGroup}>{t('Delete Group')}</Typography>
                    </>
                  )}
                </Box>
              )}
            </Badge>
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'center', marginTop: '8px'}}>
            {this.showIcons(group, isMember, status)}
          </Grid>
        </Box>
        {(isMember || group?.attributes?.group_type === "public") ? (
          this.showPosts(group, hashtags, isMember, recentGroups)
        ) : (
          <div style={{ display: "flex" }}>
            <Typography>{t("This group is private. To access posts, please join the group.")}</Typography>
          </div>
        )}
      </Container>
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openOtherComponent, group, groups, recentGroups, hashtags, isMember, status, confirmModalOpen, showSnackbar, severity, message,
      isLoadings, postInputs, postMedias, userInterests, userPostInterests, openInterests, isAttachOpens, isOpens
    } = this.state
    const currentUrl = window.location.pathname;
    if (openOtherComponent === "CreateNewGroup" || currentUrl === "/CreateNewGroup") {
      return <CreateNewGroup navigation={this.props.navigation} id={""} handleOpen={this.handleOpen} openOtherComponent={openOtherComponent}
        profileImage={this.state.userInfo.photo} />
    }
    else if ((openOtherComponent === "ViewAllGroups" || currentUrl === "/ViewAllGroups" ) && !this.state.postDetails.attributes) {
      return <ViewAllGroups navigation={this.props.navigation} id={""} handlePostDetails={this.handlePostDetails} getHandleBack={this.getHandleBack} />
    }
    return (
      <>
        {this.state.isModalMedia && <MediaSlider item={this.state.modalMediaData} allDownloadHandler={this.allDownloadHandler} onClose={this.onMediaModalCloseHandler} />}
        <Header type={openOtherComponent === "AccountGroups" ? "Groups" : ""} onSearch={this.handleSearchGroupPost}
          navigation={this.props.navigation} profileImage={this.state.userInfo.photo} notifyCount={Number(localStorage.getItem("notification_unread_count"))}
        />
        <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }} onClick={this.handleMenuClose}>
          <Grid container className="groups_cotainer">
          {this.showOtherComponents(openOtherComponent, groups, group, isMember, hashtags, status, confirmModalOpen, showSnackbar, severity, message, recentGroups)}
          </Grid>
          
          <CreatePostModal navigation={""} id={""} group={group} isOpens={isOpens} postModalHandlers={this.postModalHandlers} isEdit={this.state.isEdit}
            editPostId={this.state.editPostId} postInputHandlers={this.postInputHandlers} postInputs={postInputs} existedMedia={this.state.existedMedia}
            postMedias={postMedias} handleAttachModals={this.handleAttachModals} isAttachOpens={isAttachOpens} postMediaHandlers={this.postMediaHandlers}
            handleCloseAttachmentModals={this.handleCloseAttachmentModals} onFilteredHandlers={this.onFilteredHandlers}
            onExistedMediaHandlers={this.onExistedMediaHandlers} userPostInterests={userPostInterests} openInterests={openInterests}
            handleOpenInterests={this.handleOpenInterests} handleCloseInterests={this.handleCloseInterests} postInterestHandlers={this.postInterestHandlers}
            userInterests={userInterests} onEditApiHandlers={this.onEditApiHandlers} postApiHandlers={this.postApiHandlers}
            handleCloseSnackbars={this.handleCloseSnackbars} message={this.state.message} severity={this.state.severity} showSnackbar={this.state.showSnackbar}
            isLoadings={isLoadings} postInptHandlers={this.postInputHandlers} existedInterests={this.state.existedInterests}
            postInterestRemoves={this.postInterestRemoves} />
          <ConfirmationBox
            open={confirmModalOpen}
            handleClose={this.handleConfirmModalClose}
            handleSubmit={this.handleConfirmModalSubmit}
          />
          <Snackbar
            style={{ zIndex: 9999 }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showSnackbar}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
          >
            <MuiAlert
              onClose={this.handleCloseSnackbar}
              severity={severity}
            >
              {message}
            </MuiAlert>
          </Snackbar>
        </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  groupContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '55px',
    width: "100%",
  },
  groupImage: {
    borderRadius: "50%",
    border: "2px solid #fff",
  },
}
// Customizable Area End

import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    // Customizable Area Start
    Grid,
    Paper,
    Badge,
    Avatar,
    Divider,
    // Customizable Area End
    IconButton,
} from "@material-ui/core";

// Customizable Area Start
import "../assets/membersdirectory.scss"
import Header from "../../../components/src/Header.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { user4 } from "./assets";
import { t } from "i18next";
import Dropdown from "./components/Dropdown";
import MemberInfo from "../src/components/MemberInfo";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

import MemberDetailsController, {
    Props,
    configJSON,
} from "./MemberDetailsController.web";
import UserAboutMe from "../../user-profile-basic/src/components/UserAboutMe.web";

export default class MemberDetails extends MemberDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    userInfo = {
        name: 'Arena Mccoy',
        photo: undefined,
        description: 'Dedicated to fostering community growth through collaborative efforts and innovative solutions',
        email: 'em1@yopmail.com',
        mobile: '+111111114',
        location1: 'New York, Southern State, U.S',
        location2: 'Pacific Daylight Time - Los Angeles (GMT-7)',
        workingLanguage: 'English, Hindi',
        appLanguage: 'English',
        pbaConnection: 'Trainer, Alumni',
        skills: [
            'Football', 'drawing', 'skee', 'dacing', 'teaching ideas', 'property rent & sale'
        ],
        intrests: [
            'Football', 'drawing', 'skee', 'dacing', 'teaching ideas', 'property rent & sale'
        ],
        organizationalAffilations: [
            'Dedicated to fostering community growth through efforts and innovative solutions',
            'Dedicated to fostering communit',
            'https://uiw.builder.ai/uiworkspace/2539/whiteboard'
        ],
        experienceSummary: [
            {
                title: "Software Engineer",
                description: [
                    "Dedicated to fostering community growth through efforts and innovative solutions",
                    "Dedicated to fostering communit",
                    "Ready to role",
                ]
            },
            {
                title: "Devloper",
                description: [
                    "Dedicated to fostering community growth through efforts and innovative solutions",
                    "Dedicated to fostering communit",
                    "Ready to role",
                ]
            },
        ],
        groups: [{
            logo: user4,
            title: 'Global Climate'
        },
        {
            logo: user4,
            title: 'Global Climate'
        },
        {
            logo: user4,
            title: 'Global Climate'
        },
        {
            logo: user4,
            title: 'Global Climate'
        },
        {
            logo: user4,
            title: 'Global Climate'
        }]
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <Header type={"MemberDetails"} navigation={this.props.navigation} />
                <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
                    <Grid container className="member_directory_page">
                        <Grid container spacing={4} style={{ maxWidth: '100%', width: '100%' }}>
                            <Grid item xs={12} md={8} lg={8} xl={8} sm={12}>
                                <Paper elevation={3} className="members_directory_list" >
                                    <Box>
                                        <div onClick={this.goBackToMemberDirectory} className="member_details_container">
                                            <Badge>
                                                <ArrowBackIcon
                                                    className="arrow_button"
                                                ></ArrowBackIcon>
                                            </Badge>
                                            <Typography
                                                variant="subtitle2"
                                                className="member_details_text"
                                            >{t("Member Details")}</Typography>
                                        </div>
                                        <MemberInfo user={this.state.data} />
                                    </Box>
                                </Paper>

                            </Grid>
                            <Grid item xs={12} md={3} lg={3} xl={3} sm={12}>
                                <Paper elevation={3} >
                                    <Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            // <></>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

};
// Customizable Area End

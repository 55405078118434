import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export interface ICard {
  description?: string,
  language: string,
	attachment_id: number,
	filename: string,
	url: string,
  type: string,
	created_at?: string
}
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  activeTab: string;
  cardsData: any[];
  tabs: any[];
  selectedTab: string;
  isHoverButtons: boolean;
  selectedCard: any;
  isPreviewOpen: boolean;
  carouselsCardData: any;
  isVideoPlaying: boolean;
  isChrome: boolean;
  currentIndex: number;
  previewData: {
    attachment_id: number, 
    url: string, 
    filename: string,
    language: string,
    type : string,
    description: string
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  getResoursesApiCallId: string = '';
  videoRef: React.RefObject<HTMLVideoElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      activeTab: '1',
      arrayHolder: [],
      token: '',
      cardsData: [],
      tabs: [{ name: 'All', tab: 'all' }, { name: "Image", tab: "image" }, { name: "Video", tab: 'video' }, { name: "Audio", tab: "audio" }, { name: "Documents", tab: "application" }],
      selectedTab: "",
      isHoverButtons: false,
      selectedCard: null,
      carouselsCardData: null,
      isPreviewOpen: false,
      isVideoPlaying: false,
      isChrome: false,
      currentIndex: 0,
      previewData: {
        attachment_id: 0, 
        url: '', 
        filename: '',
        language: '',
        type : '',
        description: ''
      }
    }
    this.videoRef = React.createRef<HTMLVideoElement>();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const isChrome = window.navigator.userAgent.includes('Chrome') && !window.navigator.userAgent.includes('Edg') 
    this.setState({isChrome: isChrome})
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.setState({ selectedTab: "all" })
    this.getResoursesList()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleActiveTab = (active: string, tab: string) => {
    this.setState({ activeTab: active, selectedTab: tab })
  }

  handleUiActiveTab = (active: string) => {
    return this.state.activeTab === (active || '1') ? 'active' : ''
  }
  handleMouseEnter = (cardValue: any) => {
    this.setState({ isHoverButtons: true, selectedCard: cardValue })
  }

  handleMouseLeave = () => {
    this.setState({ isHoverButtons: false })
  }

  handlePreviewOpen = (cardValue: any, card: any, index: number) => {
    this.setState({ isPreviewOpen: true, selectedCard: card, carouselsCardData: card, previewData: card , currentIndex: index})
  }

  onClosePreview = () => {
    this.setState({ isPreviewOpen: false })
  }

  handlePlayIcon = () => {
    if(this.videoRef && this.videoRef.current) {
      this.setState({isVideoPlaying: true})
      this.videoRef.current.play()
    }
      else this.setState({isVideoPlaying: false})
  }

  handlePauseIcon = () => {
    this.setState({isVideoPlaying: false})
  }

  async downloadFile(url: string, filename: string) {
    try {
      const response = await fetch(url);

      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }

  handleHeightBox = () => {
    if (this.state.selectedTab === "application") {
      return "100%"
    }
  }

  handleCheckTextLength = () => {
    return this.state.carouselsCardData.description?.slice(0, 165)?.length>164?this.state.carouselsCardData.description?.slice(0, 165)+'...':this.state.carouselsCardData.description
  }

  onNextHandler = () => {
    if(this.state.currentIndex + 1 < this.state.cardsData.length) {
        this.setState({currentIndex : this.state.currentIndex + 1}) 
        this.state.cardsData.forEach((item:any, index:number) => {
            if(index === (this.state.currentIndex+1)) {
                this.setState({previewData: item})
            }
        })
    }
  };

  onPrevHandler = () => {
    if(this.state.currentIndex > 0) { 
        this.setState({currentIndex : this.state.currentIndex - 1})
        this.state.cardsData.forEach((item:any, index:number) => {
            if(index === (this.state.currentIndex-1)) {
                this.setState({previewData: item})
            }
        })
    }
  };

  handleRenderResoursesData = (apiResuestCallId: any, responseJson: any) => {
    if (
      this.getResoursesApiCallId === apiResuestCallId &&
      responseJson
    ) {
      if (!responseJson.errors && responseJson.data) {
        this.setState({ cardsData: responseJson.data });
      }
    }
  }

  getResoursesList = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getResoursesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resoursesListPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiResuestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleRenderResoursesData(apiResuestCallId, responseJson)
    // Customizable Area End
  }

  playAudio = () => {
    document.addEventListener('play', function (e) {
      const audio = document.getElementsByTagName('audio');
      for (let item of audio) {
        if (item != e.target) {
          item.pause();
        }
      }
    }, true);
  };

  // Customizable Area End
}


import * as React from 'react';
import { Tabs, Tab, Typography, Box, styled } from '@material-ui/core';
import { GroupMediaData } from "./groupMediaData.web";
import {t} from "i18next";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const GroupMediaTabs = ({ groupMediaData, handleEndPoint, handlePostDetails, groupId }: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange: any = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs test-id="val" value={value} onChange={handleChange}  aria-label="basic tabs example">
          <StyledTab label={t('Image')} test-id="images" {...a11yProps(0)} onClick={() => handleEndPoint('account/user_images')} />
          <StyledTab label={t('Video')} test-id="videos" {...a11yProps(1)} onClick={() => handleEndPoint('account/user_videos')} />
          <StyledTab label={t('Audio')} test-id="audios" {...a11yProps(2)} onClick={() => handleEndPoint('account/user_audios')} />
          <StyledTab label={t('Documents')} test-id="documents" {...a11yProps(3)} onClick={() => handleEndPoint('account/user_documents')} />
        </StyledTabs>
      </Box>
      <CustomTabPanel index={0} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"images"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>

      <CustomTabPanel index={1} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"videos"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>

      <CustomTabPanel index={2} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"audios"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>

      <CustomTabPanel index={3} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"documents"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>
    </Box>
  );
}

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    display:'flex',
    gap:'16px'
  }
})

  const StyledTab = styled(Tab)({
        color: "#475569",
        textTransform: "none",
        fontWeight: 700,
        fontFamily: "Comfortaa-Bold, sans-serif" ,
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "22px",
        minWidth: "auto",
        padding: "0 2px",
        '&.Mui-selected' : {
            color: "#5497FF",
            borderBottom: "2px solid #5497FF"
        }
})
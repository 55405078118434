import React, { useState } from "react";
import {
    Paper,
    ListItemText,
    Box,
    Grid,
    FormControl,
    FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/styles';
import { Divider } from "react-native-elements";
import { t } from "i18next";
import CustomDropDownField from "./CustomDropDownField.web";

export const CustomPaperC = styled(Paper)(() => ({
    borderRadius: '0 0 8px 8px',
    borderTop: 'none',
    overflowY: 'auto',
    backgroundColor: '#fff',
    margin: 0,
    width: '100%',
    borderLeft: '1px solid #CBD5E1',
    borderRight: '1px solid #CBD5E1',
    borderBottom: '1px solid #CBD5E1',
    scrollbarWidth: 'none',
    '& .MuiAutocomplete-listbox': {
        padding: 0,
        margin: '0px 10px 0px 0px',
        maxHeight: '20vh',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CFCFCF',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-tracks': {
            backgroundColor: '#F1F5F9',
            borderRadius: '4px',
        },
    }
}));

interface OptionsType {
    name: string
    id: any
}
interface InterfaceProps {
    options: OptionsType[]
    title: string;
    initialValue: any;
    onChange: Function;
    placeHolder?: string
}

const StateDropDown = (props: InterfaceProps) => {
    const [paper, setPaper] = useState(false);
    return (
        <>
            <Grid item xs={12} lg={12} sm={12} md={12} style={{marginTop: '16px'}}>
                <FormControl fullWidth>
                    <FormLabel className="dropdown_title"
                        style={{
                            color: '#475569',
                            fontSize: '14px',
                            fontFamily: "Nunito-Regular",
                            lineHeight: '22px',
                            marginBottom: '5px',
                            fontWeight: 600
                        }}
                    >
                        {t(`${props.title}`)}
                    </FormLabel>
                    <div>
                        <Autocomplete
                            id="combo-box-demo"
                            data-test-id='state-custom-auto-complete'
                            options={props.options}
                            getOptionLabel={(option) => option.name}
                            style={{
                                borderColor: '#475569'
                            }}
                            onChange={(event: any, value: any) => props.onChange(event, value)}
                            open={paper}
                            value={props.initialValue}
                            onOpen={() => setPaper(true)}
                            onClose={() => setPaper(false)}
                            popupIcon={<ExpandMoreIcon />}
                            disablePortal
                            blurOnSelect
                            PaperComponent={({ children }) => {
                                return (
                                    <CustomPaperC >
                                        <Divider style={{
                                            marginTop: "4px",
                                            marginBottom: "12px",
                                            marginLeft: "12px",
                                            marginRight: "12px"
                                        }} />
                                        {children}
                                    </CustomPaperC>
                                );
                            }}
                            renderOption={(object) =>
                                <Box>
                                    <ListItemText
                                        primary={object.name}
                                        className="dropdown_lang_options"
                                    />
                                </Box>
                            }
                            renderInput={(params) =>
                                <CustomDropDownField params={params} placeholder={props?.placeHolder} open={paper} />}
                        />
                    </div>              
                </FormControl>
            </Grid>
        </>

    );
};

export default StateDropDown;



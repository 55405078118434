import React from "react";
// Customizable Area Start

import { Grid, Container, Typography, Tab,Portal } from '@material-ui/core';
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { signupSideImg, logoImg, signupSidebox, interestBottomRight } from "./assets";
import "../assets/index.scss";
import SignUpForm from "./Components/SignUp.web";
import SignInForm from "./Components/SignIn.web";
import InterestForm from "./Components/interest.web";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { isFormType } from "../../../components/src/NativeWebRouteWrapper/Utils";
import {t} from "i18next";

// Customizable Area End

import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";


export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start

            <main>
                <div className="signup-box">
                    {this.state.allValidate === false ?
                    <Container className="signup-container">
                        <Grid container>
                            <Grid className="side-box" item xs={12} lg={6} sm={12} md={6}>
                                <div onClick={this.handleRegLogoRedirection} className="logo-item-signup-responsive">
                                    <img src={logoImg} alt="" className="logo-image-signup" />
                                    <Typography className="logo-content-01">
                                        {t('Connecting Collaborative Changemakers')}
                                    </Typography>
                                </div>
                                <div className="side-img-div">
                                <img className="side-img" src={signupSideImg} alt="" />
                                </div>
                                <Typography className="signup-content">{t('Connect, Share, and Explore with us!')}</Typography>
                                <Typography className="signup-content">{t('Welcome to our community of collaborative changemakers!')}</Typography>
                            </Grid>
                            <Grid className="form-box" item xs={12} lg={6} sm={12} md={6}>
                                <Container className="signup-form-container">
                                    <div onClick={this.handleRegLogoRedirection} className="logo-item-signup">
                                        <img src={logoImg} alt="" className="logo-image-signup" />
                                        <Typography className="logo-content-01">
                                            {t('Connecting Collaborative Changemakers')}
                                        </Typography>
                                    </div>
                                    <TabContext value={isFormType()}>
                                        <TabList
                                            onChange={this.handleFormChange}
                                            aria-label="lab API tabs example"
                                            TabIndicatorProps={{
                                                style: { display: 'none' }
                                              }}
                                        >
                                            <Tab value="signin" className={isFormType() == "signin" ? "active-tab" : "deactive-tab"} label={t('Sign in')} />
                                            <Tab value="signup" className={isFormType() == "signup" ? "active-tab" : "deactive-tab"} label={t('Sign up')} />
                                        </TabList>
                                        <TabPanel value="signin">
                                            <SignInForm state={this.state} handleLogin={this.handleLogin} handleLoginChange={this.handleLoginChange} handleLoginShowPassword={this.handleLoginShowPassword} goToForgotPage={this.goToForgotPage}/>
                                        </TabPanel>
                                        <TabPanel value="signup">
                                            <SignUpForm state={this.state} handleNext={this.handleNext} handleChange={this.handleChange} handleShowConfirmPassword={this.handleShowConfirmPassword} handleShowPassword={this.handleShowPassword} handleInfoClick={this.handleInfoClick} handleInfoClose={this.handleInfoClose} handleOpenCountry={this.handleOpenCountry} handleCloseCountry={this.handleCloseCountry} handleOpenLanguage={this.handleOpenLanguage}
                                            handleCloseLanguage={this.handleCloseLanguage}
                                            />
                                        </TabPanel>
                                    </TabContext>
                                </Container>
                            </Grid>
                        </Grid>
                        <img className="side-img-box" src={signupSidebox} alt=""/>
                    </Container>:
                    <Container>
                       <InterestForm handleRegLogoRedirection={this.handleRegLogoRedirection} handleRemoveAddMoreFields={this.handleRemoveAddMoreFields} state={this.state} handleOtherFields={this.handleOtherFields} handleContinue={this.handleContinue} handleSelectInterest={this.handleSelectInterest} handleShowFields={this.handleShowFields} handleAddFormFields={this.handleAddFormFields}/>
                       <div className="interestimg"><img src={interestBottomRight} alt=""/></div>
                    </Container> 
                    }
                </div>
                <Portal>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={this.state.showSnackbar}
                        autoHideDuration={5000}
                        onClose={this.handleCloseSnackbar}
                    >
                        <MuiAlert
                            onClose={this.handleCloseSnackbar}
                            severity={this.state.severity}
                        >
                            {this.state.message}
                        </MuiAlert>
                    </Snackbar>
                </Portal>
            </main >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isMenuOpen: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
   
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        isMenuOpen: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  handleOpenDrawer = () => {
    this.setState({isMenuOpen: true})
  }

  handleCloseDrawer = () => {
    this.setState({isMenuOpen: false})
  }

  handleLearnMore = () => {

  }

  handlePocketGuide = () => {

  }

  handleJoinNetwork = () => {
    localStorage.setItem("formType", "signin");
    this.props.navigation.navigate("EmailAccountRegistration");
  }
 // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

interface S {
    data: any
}

interface SS {}

export default class MemberDetailsController extends BlockComponent<Props, S, SS> {
    getMemberDetails:string=""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
        ];
    
        this.state = {
            data: {}
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }
      async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
    
          if (apiRequestCallId != null) {
             if(apiRequestCallId === this.getMemberDetails){
              this.setState({
                data: responseJson?.data
              }); 
            }
      }
    }
        // Customizable Area End
      }
      async componentDidMount() {
        const memberData = await getStorageData("dataId")
        this.getMemberDetailsApiCall(memberData)
        
      }
      goBackToMemberDirectory=()=>{
        this.props.navigation.goBack()
      }
      getMemberDetailsApiCall = (memberId: any) => {
        const header = {
          "Content-Type": "application/json",
          "token": String(localStorage.getItem("authToken")),
      };
      
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.getMemberDetails = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account/find_user/${memberId}`
      );
      
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
      
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
      // return this.getMemberDetails;
      }
}
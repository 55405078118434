import React, { useState } from 'react';
import {
    Paper,
    Chip,
    Button,
    Modal,
    Typography,
    Grid,
    Box,
    InputBase
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { Add } from '@material-ui/icons';
import { t } from 'i18next';

export const CustomChip = styled(Chip)(() => ({
    '&.MuiChip-root': {
        background: '#5497FF',
        margin: '0px 12px 12px 0px',
        paddingRight: '5px',
        maxHeight: '60px',
        height: 'auto',
        minHeight: '32px'
    },
    '& .MuiChip-label': {
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Nunito-Regular',
        lineHeight: '22px',
        whiteSpace: 'normal',
        textOverflow: 'ellipsis',
        wordBreak: 'break-all',
        '@media (max-width: 400px)': { 
            fontSize: '12px', 
        },
    }
}));
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 588,
    borderRadius: '8px',
    outline: 'none',
};
interface ChipData {
    id: number;
    name: string;
}
interface ChipsTypes {
    data: ChipData[]
    list: any[]
    selectedList: any[]
    modalText: string
    placeHolderText: string
    buttonText: string
    title: string
    handleDelete: Function
    addToUserList: Function
    handleAddNewInterest: Function
    handleAddExistingInterest: Function
    handleDeleteExistingInterest: Function
    handleOpenModal: Function
    errorMessage: string
}

const CustomChips = (props: ChipsTypes) => {
    const [open, setOpen] = useState(false);
    const [openAddField, setOpenAddField] = useState(false)
    const [newHobby, setNewHobby] = useState('')
    const [error, setError] = useState(false)
    const handleOpen = () => {
        props.handleOpenModal()
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
        setOpenAddField(false)
        setNewHobby('')
        setError(false)
    };
    const addNewHobby = () => {
        const newData = {
            id: newHobby,
            name: newHobby
        }
        if (props.list.some(item => item.attributes.name.toLowerCase().trim() === newHobby.toLowerCase().trim())) {
            setError(true)
        }
        else {
            props.handleAddNewInterest(newData)
            setNewHobby('')
        }
    }
    const showError = () => {
        return (
            <Typography variant="h6" className='validation_error_msg'>
                {props.errorMessage}
            </Typography>
        )
    }
    return (
        <div>
            <Paper component="ul" className={'chips_container'}>
                {props.data.length > 0 && props.data.map((data) => {
                    return (
                        <li key={data.id}>
                            <CustomChip
                                data-test-id={`hobbyChip-${data.name}`}
                                label={data.name}
                                onDelete={() => props.handleDelete(data)}
                                deleteIcon={<ClearSharpIcon
                                    className='clearSharpIcon-chip' />}
                            />
                        </li>
                    );
                })}

            </Paper>
            <Button
                data-test-id='modal-open-click'
                style={{
                    padding: 0,
                    margin: '12px 0px 0px 0px',
                    fontFamily: "Comfortaa-Bold",
                    fontSize: '12px',
                    color: '#5497FF',
                    fontWeight: 700,
                    lineHeight: '18px'
                }} onClick={handleOpen}
            >{t(`${props.modalText}`)}
            </Button>
            <Modal open={open} onClose={handleClose} data-test-id='modal-close-click'>
                <Box sx={style} className='modal_box'>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                        <Box className='add_new_skill_box'>
                            <Typography variant="h6" className='add_new_skill_title'>
                                {t(`${props.title}`)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                        <Box className="interest-section">
                            <>
                                {props.list.map((data: any, indx: number) => {
                                    const isSelected = props.selectedList.some(selected => selected.id === data.id);
                                    return (
                                        <Chip
                                            data-test-id={`chip-${indx}`}
                                            key={indx}
                                            className={isSelected ? "skill_chip-label-selected" : "skill_chip-label"}
                                            icon={isSelected ? <></> : <Add className="icon-style" />}
                                            label={data.attributes?.name}
                                            onClick={() => props.handleAddExistingInterest(data)}
                                            onDelete={() => props.handleDeleteExistingInterest(data)}
                                            deleteIcon={isSelected ? <ClearSharpIcon
                                               className='cross-icon-style' /> : <></>}
                                        />
                                    )
                                })}
                                <Chip
                                    data-test-id='other-chip'
                                    className={openAddField ? "skill_chip-label-selected" : "skill_chip-label"}
                                    icon={openAddField ? <></> : <Add className="icon-style" />}
                                    label={t('Other')}
                                    onClick={() => { setOpenAddField(true) }
                                    }
                                    onDelete={() => {
                                        setOpenAddField(false)
                                        setNewHobby('')
                                    }}
                                    deleteIcon={openAddField ? <ClearSharpIcon
                                        className='cross-icon-style' /> : <></>}
                                />
                            </>
                            {openAddField && <><Grid container item xs={12} lg={12} sm={12} md={12}
                                className='input_container'
                            >
                                <InputBase
                                    className='add_input_base'
                                    placeholder={t(t(`${props.placeHolderText}`))}
                                    value={newHobby}
                                    onChange={(e: any) => {
                                        setNewHobby(e.target.value.trimStart())
                                        setError(false)
                                    }}
                                    inputProps={{ maxLength: 42}}
                                    multiline={true}
                                    
                                />
                                <Button style={{
                                    opacity: newHobby.length === 0 ? 0.5 : 1
                                }}
                                    className="add-hobby-button"
                                    disabled={newHobby.length === 0}
                                    data-test-id='add-hobby-button'
                                    onClick={addNewHobby}
                                >{t(`${props.buttonText}`)}</Button>
                            </Grid>
                                {error && showError()}</>
                            }
                        </Box>
                        <Grid item className="add_skill_button" xs={12} lg={12} sm={12} md={12}>
                            <Button
                                type="button"
                                data-test-id='add-button'
                                onClick={() => {
                                    props.addToUserList()
                                    setOpen(false)
                                    setNewHobby('')
                                    setOpenAddField(false)
                                }}
                                className= {props.selectedList.length === 0 ? "add_btn_blur" : "add_btn"}
                                variant="contained"
                                disabled={props.selectedList.length === 0}
                                color="primary">{t('ADD')}</Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </div>

    );
}

export default CustomChips
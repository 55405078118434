import React, { useState } from "react";
// Customizable Area Start
import { Button, Grid, Box, Typography, Chip, Avatar, Container } from "@material-ui/core";
import { t } from "i18next";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { user4 } from '../assets'
interface MemberInfoInterface {
  user: any
}
interface BasicDetailsDataInterface {
  leftText: string
  rightText: string
}
interface HobbyDetailsDataInterface {
  title: string
  hobby: string[]
}
interface UserInfoInterface {
  text: string
  style: Object
}
const MemberInfo = (props: MemberInfoInterface) => {
  const { user } = props


  const renderBasicDetails = (basicDetailsData: BasicDetailsDataInterface) => {
    return (
      <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0' }}>
        <Grid className="md_first_title" item xs={6} sm={6}>
          <Typography className="md_first_text">{t(basicDetailsData.leftText)}</Typography>
        </Grid>
        <Grid container className="md_first_value" item xs={6} sm={6}>
          <Typography className="md_first_text">{basicDetailsData.rightText}</Typography>
        </Grid>
      </Grid>
    )
  }

  const renderHobbyDetails = (hobbyDetails: HobbyDetailsDataInterface) => {
    const [showAll, setShowAll] = useState(false);
    const displayedHobbies = showAll ? hobbyDetails.hobby : hobbyDetails.hobby?.slice(0, 3);
    const remainingCount = hobbyDetails.hobby?.length - displayedHobbies?.length;

    return (
      <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0' }}>
        <Grid item xs={12} sm={6} className="md_first_title">
          <Typography className="md_first_text">{t(hobbyDetails.title)}</Typography>
        </Grid>

        <Grid container item xs={12} sm={6} className="md_skills_grid">
          <div className="container-hobby-details" style={{
            display: "flex",
            flexWrap: "wrap",
          }}>
            {displayedHobbies?.map((item: string) => {
              return (
                <Chip
                  label={item}
                  className="md_view_skills_chips"
                />
              )
            })}
            {remainingCount > 0 && !showAll &&
              <Button variant="text" onClick={() => setShowAll(true)}
                className="md_show_less">{t('+')}{remainingCount} {t('more')}
              </Button>}
            {showAll &&
              <Button variant="text" onClick={() => setShowAll(false)}
                className="md_show_less">{t('Show Less')}
              </Button>}
          </div>
        </Grid>
      </Grid>
    )
  }

  const renderGroupDetails = (groupsData: any) => {
    const [showAll, setShowAll] = useState(false);
    const displayeGroups = showAll ? groupsData : groupsData?.slice(0, 3);
    const remainingCount = groupsData?.length - displayeGroups?.length;
    return (
      <Grid spacing={0}>
        <Grid item className="md_group_container">
          <Typography className="group_title_text">{t('Groups')}</Typography>
        </Grid>
        <Grid item className="group_section">
          {displayeGroups?.map((section: any, index: number) => {
            return (
              <Box key={index}
                sx={{
                  marginBottom: '16px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <Avatar
                  src={section.logo}
                  alt="Group_logo"
                  style={{
                    width: 48,
                    height: 48,
                  }}
                />
                <Typography className="md_groups_text">{t(section.title)}</Typography>
              </Box>

            )
          })}
        </Grid>
        {remainingCount > 0 && !showAll &&
          <Button variant="text" onClick={() => setShowAll(true)}
            className="group_show_less">{t('+')}{remainingCount} {t('more')}
          </Button>}
        {showAll &&
          <Button variant="text" onClick={() => setShowAll(false)}
            className="group_show_less">{t('Show Less')}
          </Button>}
      </Grid>)
  }

  const renderPointsList = (items: string) => {

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              marginLeft: '8px',
              maxWidth: '459px'
            }}
          >
            <h1 className="md_affilation_text" dangerouslySetInnerHTML={{__html:items}}/>
          </Box>
        </Grid>
      </Grid>
    )

  }
  const renderUserInfo = (info: UserInfoInterface) => {
    return (
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} container style={{ justifyContent: 'center' }}>
        <Typography
          variant="subtitle2"
          style={info.style}
        >{info.text}</Typography>
      </Grid>
    )
  }
  return (
    <Box>
      <Box>
        <Grid container className="md_details_container">
          <Grid item container xs={2}></Grid>
          <Grid item container xs={8} style={{ justifyContent: 'center' }}>
            <Avatar
              src={(user?.attributes?.photo)}
              alt="User Profile Picture"
              style={{
                width: 120,
                height: 120,
              }}
              data-test-id='member-info-avatar'
            />
          </Grid>
        </Grid>
        {renderUserInfo({
          text: user?.attributes?.full_name, style: {
            fontFamily: 'Nunito-Regular',
            color: '#475569',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            marginTop: '8px',
            textAlign: 'center',
            wordBreak: 'break-word'
          }
        })}
        {renderUserInfo({
          text: user?.attributes?.about_yourself, style: {
            fontFamily: 'Nunito-Regular',
            color: '#475569',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            marginTop: '8px',
            maxWidth: '453px',
            textAlign: 'center'
          }
        })}
      </Box>
      <Box style={{ marginTop: '16px' }}>
        <Container disableGutters style={{}}>
          {renderBasicDetails({ leftText: 'Email Address', rightText: user?.attributes?.email })}
          {renderBasicDetails({ leftText: 'Mobile Number', rightText: user?.attributes?.number_visible ? user?.attributes?.full_phone_number : '**********' })}
          <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0' }}>
            <Grid container item xs={6} sm={6} style={{ padding: '18px 0px 18px 0px', alignItems: "center" }}>
              <Typography className="md_first_text">{t('Lives in and Time Zone')}</Typography>
            </Grid>
            <Grid container item xs={6} sm={6} className="md_first_value">
              <Typography className="md_location_text">{user?.attributes?.city} {user?.attributes?.state}, </Typography>
              <Typography className="md_location_text2">{user?.attributes?.time_zone}</Typography>
            </Grid>
          </Grid>
          {renderBasicDetails({ leftText: 'Working language', rightText: user?.attributes?.working_language })}
          {renderBasicDetails({ leftText: 'App preferred language', rightText: user?.attributes?.preferred_language })}
          {renderBasicDetails({ leftText: 'PBA Connection', rightText: user?.attributes?.connections?.map((val: any) => (val?.name))?.join(", ") })}
          {renderHobbyDetails({ title: 'Skills', hobby: user?.attributes?.skill_name?.map((val: any) => (val?.name)) })}
          {renderHobbyDetails({ title: 'Interests', hobby: user?.attributes?.interest_name?.map((val: any) => (val?.name)) })}
          <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0', paddingBottom: '16px' }}>
            <Grid className="md_first_title">
              <Typography className="md_first_text">{t('Organizational affiliations and Socials')}</Typography>
            </Grid>
            {renderPointsList(user?.attributes?.organizational_affilation)}
          </Grid>
          <Grid container spacing={0}>
            <Grid className="md_first_title">
              <Typography className="md_first_text">{t('Summary of Experience')}</Typography>
            </Grid>
            <Grid container>
              <Box
                sx={{
                  marginBottom: '16px',
                  paddingBottom: "16px",
                  borderBottom: '1px solid #E2E8F0'
                }}>
                {renderPointsList(user?.attributes?.experience)}
              </Box>
            </Grid>
          </Grid>
          {renderGroupDetails(user.groups)}
        </Container>
      </Box>
    </Box>
  );
};
// Customizable Area End

export default MemberInfo;

import React from "react";
// Customizable Area Start
import { Typography, Avatar, Divider, Box, Grid, Input } from '@material-ui/core';
import { LikeButton } from "../../../../components/src/LikeButton";
import { MediaGrid, MediaWithGrid, MediaWithoutGrid } from "../../../accountgroups/src/components/media.web";
import SendIcon from '@material-ui/icons/Send';
import {t} from "i18next";
import { LikeShareButton } from "../../../../components/src/LikeShareButton";

interface UserPostProps {
  posts: any;
  usercommentInput?: string,
  onuserCommentChange: (e: any) => void;
  onUserCommentHandler: (id: number) => boolean;
  onLikeHandler: (id: number, isLike: boolean, likeId: any) => void;
  onShareInputHandler: (id: string) => void;
  commentBoxVisibilityHandler: (index: number) => void;
}

export const PostMedia = ({ length, array, onMediaModalHandler, playVideo, playAudio }: any) => {
  if (length >= 5) {
    return array.slice(0, 4).map((item: any, idx: number) => (
      <MediaGrid mediaArr={array} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else if (length === 1) {
    return array.map((item: any, idx: number) => (
      <MediaWithoutGrid mediaArr={array} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else {
    return array.map((item: any, idx: number) => (
      <MediaWithGrid mediaArr={array} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  }
}

const UserPost = (props: any) => {
  const { posts, commentInput, onCommentChange, onCommentHandler, onLikeHandler, commentBoxVisibilityHandler,
    isCommentEnable, commentIndex, replyIndex, commentActiveIndex, isReplied, repiedIndex, isCommentActive, isReportModal, reportIndex,
    onRepliedHandler, onReplyHandler, onClickReplyComment, onClickCommentIndex, onReportModalClickHandler, allDownloadHandler, handleConfirmModalOpen,
    onMediaModalHandler, playVideo, playAudio, onShareInputHandler, onrhLanguageChange,onrhDescriptionChange, state, handleOnShareToResourceHub } = props
  
  const renderComments = (item: any, index: number) => {
    if (item?.attributes?.recent_comments?.data?.length > 0) {
      return item.attributes.recent_comments.data.map((comment: any, idx: number) => (
        <>
          <Box className="recentPostBox">
            <Avatar data-test-id="autherImage" src={comment.attributes.author_image} />
            <div className="commentDetail">
              <div className="userNameComment">
                <Typography className="userText" data-test-id="autherName">{comment.attributes.author_name}</Typography>
                <Typography className="tag">
                  -
                </Typography>
                <Typography className="time" data-test-id="createdAt">{comment.attributes.created_at}</Typography>
              </div>
              <Typography className="time break" data-test-id="commentText">
                {comment.attributes.comment}
              </Typography>
              <div className='replied-box'>
                {comment.attributes.reply_counts && <Typography data-test-id="repiled-click-id" className="time reply-btn" onClick={() => onRepliedHandler(index, idx)}>{comment.attributes.reply_counts} {t('Replied')}</Typography>}
                <Typography data-test-id="reply-btn" className={`time reply-btn ${index} ${idx}`} onClick={() => onReplyHandler(index, idx)}>{t('Reply')}</Typography>
              </div>
            </div>
          </Box>

          {renderPostReplyCommentInput(item, index, comment, idx)}

          {
            commentIndex === index && isReplied && repiedIndex === idx &&
            comment.attributes.comments.data.length > 0 && comment.attributes.comments.data.map((ele: any) => (
              <Box className="recentPostBox reply-loop-pd">
                <Avatar src={ele.attributes.author_image} data-test-id="autherImage" className="" />
                <div className="commentDetail">
                  <div className="userNameComment">
                    <Typography data-test-id="autherName" className="userText ">{ele.attributes.author_name}</Typography>
                    <Typography className="tag">
                      -
                    </Typography>
                    <Typography data-test-id="createdAt" className="time ">{ele.attributes.created_at}</Typography>
                  </div>
                  <Typography data-test-id="commentText" className="time break ">
                    {ele.attributes.comment}
                  </Typography>
                </div>
              </Box>
            ))
          }
        </>
      ))
    }
  }

  const renderPostCommentInput = (item: any, index: number) => {
    if ((index === commentIndex && isCommentEnable)) {
      return <Box className="doComment">
        <Input
          id="input-with-icon-adornment"
          data-test-id="comment-2"
          className="inputField"
          placeholder={t('Comment here')}
          value={commentActiveIndex === index ? commentInput : ""}
          onClick={() => onClickCommentIndex(index)}
          onChange={commentActiveIndex === index ? onCommentChange : undefined}
        />
        {index === commentActiveIndex ?
          <SendIcon
            data-test-id="send-id-2"
            style={{ margin: "0 10px", cursor: "pointer", fill: commentInput.length > 0 && isCommentActive ? '#5497FF' : "#94A3B8" }}
            onClick={() => onCommentHandler(item?.attributes.id, null)}
          /> :
          <SendIcon data-test-id="send-id-2" style={{ margin: "0 10px", cursor: "pointer", fill: "#94A3B8" }} />
        }
      </Box>
    }
  }

  const renderPostReplyCommentInput = (item: any, index: number, items: any, idx: number) => {
    if (!isCommentEnable && commentIndex === index && replyIndex === idx) {
      return <Box className="doComment">
        <Input
          data-test-id="comment"
          className="inputField"
          id="input-with-icon-adornment"
          placeholder={t('Comment here')}
          onChange={commentActiveIndex === index ? onCommentChange : undefined}
          value={commentActiveIndex === index ? commentInput : ""}
          onClick={() => onClickReplyComment(index)} />
        {commentActiveIndex === index ?
          <SendIcon
            style={{ cursor: "pointer", margin: "0 10px", fill: commentInput.length > 0 && isCommentActive ? '#5497FF' : "#94A3B8" }}
            onClick={() => onCommentHandler(item.attributes.id, items?.attributes?.id)}
            data-test-id="send-id" /> :
          <SendIcon
            style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }}
            data-test-id="send-id"
          />}
      </Box>
    }
  }
  
  return (<>
    {posts && posts.length > 0 ? 
    [...posts].reverse().map((item: any, i: number) => {
      let mediaArray = [...item.attributes.images, ...item.attributes.videos, ...item.attributes.audios, ...item.attributes.documents];
      let arr = mediaArray;
      return (<Box className="user-recentPostCard" key={`box-post-${item.id}-`} sx={webStyles.boxStyle}>
          <div className="user-recentPostBox">
            <Avatar src={item.attributes.author_image} />
            <div className="user-descriptionBox">
              <div className="user-desc">
                <Typography className="user-descTitle">
                  {item.attributes.author_name}
                </Typography>
                <div className="tag">{item.attributes.created_at}</div>
              </div>
              <Typography data-test-id="test-post-id-two" className="descText">
                {item.attributes.description.length < 350 ? item.attributes.description
                    : <>{item.attributes.description.substring(0, 350) + "... "}
                        <a data-test-id="more-id" className='seeMoreText'>{t('See more')}</a></>
                }</Typography>
              <div className="recentTags"></div>
            </div>
            <div data-test-id="report-btn" className="report-btn" onClick={() => onReportModalClickHandler(item.id)} style={{ position: "relative", marginTop: 0 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                  fill="#64748B" />
              </svg>
              {isReportModal && reportIndex == item?.id &&
                <Box test-id="reportbox01" className='report-modal' style={{ top: "85%" }}>
                  <Typography data-test-id="download-id" className='report-text' onClick={() => allDownloadHandler(arr)}>{t('Download')}</Typography>
                  <Divider style={{ width: "100%" }} />
                  <Typography onClick={() => handleConfirmModalOpen(item.id)} className='report-text' data-test-id="report-id">{t('Report')}</Typography>
                </Box>}
            </div>
          </div>
          <div className='singlePostImageContainer'>
              <Grid container style={{ justifyContent: "center", padding: "0 20px" }} spacing={1}>
                <PostMedia array={mediaArray} length={mediaArray.length} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
              </Grid>
          </div>
          <div className="user-likesCard">
            <div className="user-likesCommentsContainer">
              <Typography data-test-id="likesCount" className="tag">
                {item.attributes.post_likes}{item.attributes.post_likes < 2 ? ` ${t('Like')}` : ` ${t('Likes')}`}
              </Typography>
              <Typography className="tag">-</Typography>
              <Typography data-test-id="commentsCount" className="tag">
                {item.attributes.post_comments}{item.attributes.post_comments < 2 ? ` ${t('Comment')}` : ` ${t('Comments')}`}
              </Typography>
            </div>
            <LikeShareButton data-test-id="likeBtn" isLike={item.attributes.current_user_liked}
              onClick={() => onLikeHandler(item.id, item.attributes.current_user_liked, item.attributes.current_user_liked_id)}
              commentOnClick={() => commentBoxVisibilityHandler(i)} shareHandler={() => onShareInputHandler(item.id)} 
              onrhDescriptionChange={onrhDescriptionChange}
              onrhLanguageChange={onrhLanguageChange}
              state={state}
              authorId={state.userId}
              data={item}
              onSend={handleOnShareToResourceHub}
              />
            <Divider className="lineDivider" />
          </div>

          {renderComments(item, i)}

          {renderPostCommentInput(item, i)}
        </Box>
      )
    }) : (
      <div style={{textAlign: 'center', width: '100%'}}>
        <Typography>{t('No posts yet')}</Typography>
      </div>
    )}
  </>
  );
};

const webStyles = {
  boxStyle: {
    marginBottom: "20px",
  }
}

// Customizable Area End

export default UserPost;

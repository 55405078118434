import React from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Typography
} from "@material-ui/core";
import { t } from "i18next";

interface RenderInputProps {
    title: string;
    initialValue: string;
    name: string;
    mobile: boolean;
    showMoblieNumber?: boolean;
    required: boolean
    onChange: () => {};
    errorKey?: string
    profileErrorField?: any
    disabled?:boolean
}

const RenderInput = (props: RenderInputProps) => {
    const showError = (errorKey: any) => {
        return props.profileErrorField[errorKey] &&
            <Typography data-test-id={`error`} className="validation_check_msg">{t(props.profileErrorField[errorKey])}</Typography>
    }
    
    return (
        <Grid item xs={12} lg={6} sm={12} md={6}>
            <FormControl fullWidth>
                <FormLabel className="textinput_title">
                    {t(props.title)}
                </FormLabel>
                <TextField
                    data-test-id={`field-${props.title}`}
                    value={props.initialValue}
                    type={'text'}
                    placeholder={``}
                    className= {props.disabled ? "disabled_text-box_here" : "text-box_here"}
                    variant="outlined"
                    name={props.name}
                    onChange={props.onChange}
                    InputProps={{
                        style: {
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            color: '#1E293B',
                            cursor: props.disabled ? 'not-allowed' : 'text',
                        },
                        inputProps: {
                            maxLength: 42,
                        },
                    }}
                    disabled={props.disabled}
                />
                {props.required && showError(props.errorKey)}
            </FormControl>
        </Grid>
    )
}

export default RenderInput
import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';
import { Typography } from '@material-ui/core'
import 'jodit/build/jodit.min.css';
interface CustomEditorProps {
    item: string
    handleChange: Function
    errorKey: string
    profileErrorField: any
}
const CustomEditor = (props: CustomEditorProps) => {
    const showError = (errorKey: any) => {
        return props.profileErrorField[errorKey] &&
            <Typography data-test-id={`error`} className="validation_check_msg">{props.profileErrorField[errorKey]}</Typography>
    }
    const editor = useRef(null);

    const config = {
        buttons: ['ol', 'ul'],
        buttonsMD: ['ol', 'ul'],
        buttonsSM: ['ol', 'ul'],
        buttonsXS: ['ol', 'ul'],
        placeholder: '',
    }

    return (
        <div>
            <JoditEditor
                key={props.errorKey}
                data-test-id='custom-editer'
                ref={editor}
                value={props.item}
                config={config}
                onBlur={(value: string) => props.handleChange(value)}
            />
            {showError(props.errorKey)}
        </div>
    );
};

export default CustomEditor;
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiPutMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.getInterestAPiEndPoint = 'bx_block_interests/interests';
exports.btnExampleTitle = "CLICK ME";
exports.getCountryAPiEndPoint = 'accounts/country_code_and_flag';
exports.editProfileApiEndPoint = 'account/update';
exports.editPasswordApiEndPoint = 'account/change_password';
exports.getPBAconnectionsEndPoint= 'account/pba_connections';
exports.getUserInterestListApiEndPoint = 'bx_block_interests/impact_areas';
exports.getUserSkillListApiEndPoint = 'bx_block_interests/skills_and_approaches';
exports.getTimezonesApiEndPoint = 'account/select_timezones';
exports.wokringLanguageApiEndPoint = "account/working_language";
exports.yourOldPassText = "Your Older Password";
exports.changeYourPassText = "Change your password";
exports.currentPasswordText = "Current Password";
exports.newPasswordText = "New Password";
exports.confirmPasswordText = "Confirm Password";
exports.saveText = "SAVE";
exports.settingsText = "Settings";
exports.editProfileText = "Edit Profile";
exports.accountSettingsText = "Account settings";
// Customizable Area End
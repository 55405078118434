import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken, getUserDetails } from "../../../components/src/NativeWebRouteWrapper/Utils";
import i18next, { t } from "i18next"
type Group = {
  id: string,
  type: string,
  attributes: {
    name: string,
    group_admin_id: any,
    group_status: string,
    group_type: string,
    icon_image: string,
    cover_image: string,
    accounts: {
      id: string,
      account_id: number,
      created_at: string,
      updated_at: string,
      group_id: number,
      status: string,
      request_type: string
    }[]
    interests:
    {
      id: number,
      name: string,
      created_by_admin: boolean,
      created_at: string,
      updated_at: string,
      interest_category: string,
      status: string
    }[]
  }
}

export type Groups = Group[]

type Posts = {
  id: number,
  type: string,
  attributes: {
    id: number,
    author_name: string,
    description: string,
    body: null,
    location: null,
    post_likes: number,
    account_id: number,
    created_at: string,
    updated_at: string,
    model_name: string,
    current_user_liked: null | boolean,
    images: {
      id: number,
      filename: string,
      url: string,
      type: string
    }[],
    videos: [],
    documents: [],
    audios: []
  }
}[]

type HashTags = {
  id: string,
  type: string,
  attributes: {
    name: string,
    interest: {
      id: number,
      name: string,
      created_by_admin: boolean,
      created_at: string,
      updated_at: string,
      interest_category: string
      status: string
    }
  }
}[]

type Color = "success" | "info" | "warning" | "error" | undefined;

type PostsDetailsInterface = {
  id: number,
  type: string,
  attributes: null | {}
}
const Language: any = { english: "en", french: "fr", bengali: "bn" }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleOpen: any;
  groups: any;
  group: any
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  openOtherComponent: string;
  group: any;
  filterEndpoint: string;
  groupSearchInput: string;
  posts: any;
  commentInput: string,
  topTrendingData: Group,
  groups: any,
  recentGroups: any,
  hashtags: HashTags;
  userId: number | null;
  isCommentEnable: boolean;
  commentIndex: number | null;
  replyIndex: number | null;
  commentActiveIndex: number | null;
  isReplied: boolean;
  repiedIndex: number | null;
  isCommentActive: boolean
  postPageNumber: number
  isMember: boolean;
  status: string;
  userInfo: any;
  isReportModal: boolean;
  reportIndex: number | null;
  reportPostId: number | string;
  confirmModalOpen: boolean;
  showSnackbar: boolean;
  severity: any;
  message: string;
  isModalMedia: boolean;
  modalMediaData: any;
  groupId: any;
  joinFrom: any;
  postDetails: PostsDetailsInterface;
  postId: number,
  isEdit: boolean,
  editPostId: any
  existedMedia: any,
  isOpens: any;
  isAttachOpens: boolean;
  allMediaArrays: any;
  postMedias: any;
  postInputs: string;
  userPostInterests: any;
  openInterests: boolean;
  userInterests: any;
  isLoadings: boolean;
  mediaIndexes: any;
  isMoreHorizOpen: boolean;
  adminIds: any;
  existedInterests: any;
  groupKeys: any;
  hashtagFiltered: any;
  hashValue: any;
  isHashTag: boolean;
  pageNumber: number;
  totalPages: number;
  isLikeCommentClicked: boolean;
  postDetailToReplaceData: any
  postIndexToReplace: any
  metaData: any
  postIdToReplace: any
  isPostLoadings: any
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupApiCallId = "";
  getGroupsApiCallId = "";
  hashtagsApiCallId = "";
  getPostsApiCallId = "";
  likesApiCallId = "";
  commentApiCallId = "";
  topTrendingApiCallId = "";
  postApiCallId = "";
  joinGroupApiCallId = ""
  getRecentGroupsApiCallId = "";
  searchGroupsApiCallId = "";
  reportPostApiCallId = "";
  postDetailsApiCallId = "";
  deleteApiCallId = "";
  postCreateApiCallId = "";
  apiGetUserInterest = "";
  editApiCallId = "";
  attachDeleteApiCallId = "";
  searchGroupPostApiCallId = "";
  leaveGroupApiCallId = "";
  commentDeleteApiCallId = "";
  deleteGroupApiCallId = "";
  postDetailToReplaceApiCallId = "";
  token = getAuthToken();
  mediaArr: any = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      openOtherComponent: "AccountGroups",
      group: {} as Group,
      filterEndpoint: "",
      groupSearchInput: "",
      posts: [],
      commentInput: "",
      topTrendingData: {} as Group,
      groups: [],
      recentGroups: [],
      hashtags: [],
      userId: null,
      isCommentEnable: false,
      commentIndex: null,
      replyIndex: null,
      commentActiveIndex: null,
      isReplied: false,
      repiedIndex: null,
      isCommentActive: false,
      postPageNumber: 1,
      isMember: false,
      status: "",
      userInfo: {},
      isReportModal: false,
      reportIndex: null,
      reportPostId: "",
      confirmModalOpen: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      isModalMedia: false,
      modalMediaData: {},
      postDetails: { id: 0, type: '', attributes: null },
      postId: 0,
      groupId: "",
      joinFrom: "",
      isEdit: false,
      editPostId: "",
      existedMedia: [],
      isOpens: false,
      isAttachOpens: false,
      allMediaArrays: [],
      postMedias: [],
      postInputs: "",
      userPostInterests: [],
      openInterests: false,
      userInterests: [],
      isLoadings: false,
      mediaIndexes: [],
      isMoreHorizOpen: false,
      adminIds: [],
      existedInterests: [],
      groupKeys: {},
      hashtagFiltered: [],
      hashValue: null,
      isHashTag: false,
      pageNumber: 1,
      totalPages: 1,
      isLikeCommentClicked: false,
      postDetailToReplaceData: {},
      postIndexToReplace: 0,
      metaData: {},
      postIdToReplace: "",
      isPostLoadings: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getGroupApiCallId:
          localStorage.setItem("notify_arr", JSON.stringify({}))
          const adminIds = responseJson?.data.attributes.accounts.map((item: any) => item.is_admin && item.account_id.toString())
          const isMember = responseJson?.data.attributes.accounts.map((item: any) => item.account_id.toString())
            .includes(this.state.userId && this.state.userId.toString())
          this.setState({ group: responseJson?.data, isMember, adminIds })
          break;
        case this.getGroupsApiCallId:
          this.getGroupsApiCallHandler(responseJson.data, this.state.userId)
          break;
        case this.getRecentGroupsApiCallId:
          this.getRecentGroupsApiCallHandler(responseJson.data, this.state.userId)
          break;
        case this.joinGroupApiCallId:
          this.joinGroupApiCallHandler(responseJson.message, this.state.groupId, this.state.joinFrom)
          break;
        case this.hashtagsApiCallId:
          this.setState({ hashtags: responseJson.data });
          break;
        case this.topTrendingApiCallId:
          this.setState({ topTrendingData: responseJson.data });
          break;
        case this.searchGroupsApiCallId:
          this.getGroupsApiCallHandler(responseJson.data, this.state.userId)
          break;
        case this.getPostsApiCallId:
          let postsData = responseJson.data;
          let metaData = responseJson.meta;
          this.errorLoaderHandler(responseJson)
          this.setState({
            isPostLoadings: false,
            posts: postsData,
            metaData: metaData,
          });
          if (this.state.isHashTag) {
            let filteredWithTags = this.state.posts.filter((i: any) => i.attributes.description.includes(`#${this.state.hashValue}`))
            this.setState({ hashtagFiltered: filteredWithTags })
          }
          break;
        case this.searchGroupPostApiCallId:
          this.setState({ posts: responseJson.data });
          break;
        case this.deleteApiCallId:
          this.deleteApiCallHandler()
          break;
        case this.likesApiCallId:
          this.likesApiCallHandler(responseJson)
          break;
        case this.commentApiCallId:
          this.commentApiCallHandler(responseJson)
          break;
        case this.commentDeleteApiCallId:
          this.getPostsDetails(this.state.postId)
          this.getPosts()
          this.setState({
            isLoadings: false,
            showSnackbar: true,
            message: responseJson.message,
            severity: "success",
          });
          break;
        case this.reportPostApiCallId:
          this.reportPostApiCallHandler(responseJson)
          break;
        case this.postDetailsApiCallId:
          this.setState({ postDetails: responseJson.data });
          break;
        case this.postCreateApiCallId:
          this.postCreateApiHandler(responseJson.data)
          break;
        case this.apiGetUserInterest:
          const groupedKeys = responseJson.data.reduce((group: { [key: string]: any[] }, item: any) => {
            if (!group[item.attributes.interest_category]) {
              group[item.attributes.interest_category] = [];
            }
            group[item.attributes.interest_category].push(item);
            return group;
          }, {});

          this.setState({
            userInterests: responseJson.data,
            groupKeys: groupedKeys
          });
          this.filterInterests()
          break;
        case this.editApiCallId:
          this.editApiCallHandler()
          break;
        case this.leaveGroupApiCallId:
          this.leaveGroupApiCallHandler(responseJson.message)
          break;
        case this.deleteGroupApiCallId:
          localStorage.removeItem("new_group")
          localStorage.removeItem("edit_group")
          this.setState({
            showSnackbar: true,
            message: "Group deleted successfully",
            severity: "success",
          });
          window.location.href = "CommunityForumWeb"
          break;
        case this.postDetailToReplaceApiCallId:
          this.setState({
            postDetailToReplaceData: responseJson.data
          });
          break;
      }
    }
  }

  errorLoaderHandler = (res: any) => {
    if (res && res.message) {
      return this.setState({ isPostLoadings: false })
    }
  }

  errorHandler = (res: any) => {
    if (res && res.error) {
      this.setState({
        showSnackbar: true,
        message: res.error,
        severity: "error",
      })
      window.location.href = "/CommunityForumWeb"
    }
  }

  reportPostApiCallHandler = (responseJson: any) => {
    this.setState({ reportPostId: "" });
    if (responseJson && responseJson.message) {
      this.setState({
        showSnackbar: true,
        message: responseJson.message,
        severity: "success",
      });
    } else {
      this.setState({
        showSnackbar: true,
        message: responseJson && responseJson.errors[0] || t('something went wrong'),
        severity: "error",
      });
    }
  }

  likesApiCallHandler = (res: any) => {
    if (this.state.postDetails?.attributes) {
      this.getPostsDetails(this.state.postId)
    } else {
      this.getPosts();
    }
  }

  commentApiCallHandler = (res: any) => {
    if (this.state.postDetails?.attributes) {
      this.getPostsDetails(this.state.postId)
    } else {
      this.getPosts();
    }
    this.setState({ commentInput: "" })
  }

  deleteApiCallHandler = () => {
    this.setState({
      isOpens: false,
      showSnackbar: true,
      message: t("Post deleted successfully"),
      severity: "success",
      isPostLoadings: false
    });
  }

  editApiCallHandler = () => {
    this.setState({ allMediaArrays: [], postMedias: [], mediaIndexes: [] });
    this.getPostDetailToReplace(this.state.postIdToReplace)
    this.setState({ postPageNumber: this.state.postPageNumber + 1 })
    this.getPosts()
    this.setState({
      isOpens: false,
      isLoadings: false,
      allMediaArrays: [],
      showSnackbar: true,
      message: t("Post edited successfully"),
      severity: "success"
    });
  }

  leaveGroupApiCallHandler = (message: string) => {
    if (message) {
      this.setState({
        message: t("You left the group"),
        showSnackbar: true,
        severity: "success",
        isMember: false,
        isMoreHorizOpen: false
      })
    } else {
      this.setState({
        message: t("Please make any other member as admin"),
        showSnackbar: true,
        severity: "error",
        isMoreHorizOpen: false
      })
    }
  }

  filterInterests = () => {
    let arr: Array<{ cat: string; key: string; id: number }> = [];

    Object.keys(this.state.groupKeys).forEach((key: string) => {
      this.state.groupKeys[key].forEach((intrst: { id: number, attributes: { name: string } }) => {
        arr.push({
          cat: key,
          key: intrst.attributes.name,
          id: intrst.id
        });
      });
    });
    this.setState({ userInterests: arr })
    return arr;
  }

  postCreateApiHandler = (data: any) => {
    if (typeof data === "object") {
      this.setState({ isLoadings: false });
      this.setState({ posts: [], allMediaArrays: [], postMedias: [] });
      this.getHashtags()
      this.getPosts()
      this.setState({
        isOpens: false,
        showSnackbar: true,
        message: t("Post created successfully"),
        severity: "success",
      })
    }
    else {
      this.setState({
        showSnackbar: true,
        message: t('something went wrong'),
        severity: "error",
      });
    }
  }

  getGroupsApiCallHandler = (data: any, userId: any) => {
    const groupsData = data && data.map((item: any) => {
      for (let account of item.attributes.accounts) {
        if (account.account_id.toString() === userId.toString()) {
          return { ...item, status: account.status, request_type: account.request_type }
        }
      }
      return item
    })
    this.setState({ groups: groupsData });
  }

  handleMenuClose = () => {
    if (this.state.isMoreHorizOpen === true) {
      this.setState({ isMoreHorizOpen: false })
    }
  }

  getRecentGroupsApiCallHandler = (data: any, userId: any) => {
    const recentGroups = data && data.map((item: any) => {
      for (let account of item.attributes.accounts) {
        if (account.account_id.toString() === userId.toString()) {
          return { ...item, status: account.status, request_type: account.request_type }
        }
      }
      return item
    })
    this.setState({ recentGroups });
  }

  toGroupDetailsFromViewAllGroups = (message: any, groupId: any) => {
    let group: any;
    const groups = message && this.state.groups.map((item: any) => {
      if (item.id === groupId) {
        const data = {
          ...item, status: item.attributes.group_type === "public" ? "accepted" : "pending", request_type: "requested",
          isMember: item.attributes.group_type === "public" ? true : false
        }
        group = data
        return data
      }
      return item
    })
    this.setState({ groups, group, showSnackbar: true, message, severity: "success", isMember: group?.isMember, joinFrom: "" },
      () => this.getGroup()
    );
  }

  toGroupDetailsFromRecentGroups = (message: any, groupId: any) => {
    let group: any;
    const recentGroups = message && this.state.recentGroups.map((item: any) => {
      if (item.id === groupId) {
        const data = {
          ...item, status: item.attributes.group_type === "public" ? "accepted" : "pending", request_type: "requested",
          isMember: item.attributes.group_type === "public" ? true : false
        }
        group = data
        return data
      }
      return item
    })
    this.setState({ recentGroups, group, showSnackbar: true, message, severity: "success", isMember: group && group.isMember, joinFrom: "" },
      () => this.getGroup()
    );
  }

  joinFromRecentGroups = (message: any, groupId: any) => {
    const recentGroups = message && this.state.recentGroups.map((item: any) => {
      if (item.id === groupId) {
        return { ...item, status: item.attributes.group_type === "public" ? "accepted" : "pending", request_type: "requested" }
      }
      return item
    })
    this.setState({ recentGroups, showSnackbar: true, message, severity: "success", joinFrom: "" });
  }

  joinFromViewAllGroups = (message: any, groupId: any) => {
    const groups = message && this.state.groups.map((item: any) => {
      if (item.id === groupId) {
        return { ...item, status: item.attributes.group_type === "public" ? "accepted" : "pending", request_type: "requested" }
      }
      return item
    })
    this.setState({ groups, showSnackbar: true, message, severity: "success", joinFrom: "" });
  }

  joinGroupApiCallHandler = (message: any, groupId: any, joinFrom: any) => {
    if (joinFrom === "viewAllGroups") {
      this.toGroupDetailsFromViewAllGroups(message, groupId)
    }
    else if (joinFrom === "recentGroups") {
      this.toGroupDetailsFromRecentGroups(message, groupId)
    }
    else if (joinFrom === "joinFromViewAllGroups") {
      this.joinFromViewAllGroups(message, groupId)
    }
    else if (joinFrom === "joinFromRecentGroups") {
      this.joinFromRecentGroups(message, groupId)
    }
    else {
      this.toGroupDetailsFromViewAllGroups(message, groupId)
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scroll(0, 0)
    this.getRecentGroups();
    this.getHashtags();
    this.getNowTrending();
    const group = this.getDataFromLocalStorage()
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!)
    const joinFrom = localStorage.getItem("join_from") || "viewAllGroups"
    if (Object.keys(group).length) {
      this.setState({ userInfo, userId: userInfo.id, group, joinFrom }, () => {
        this.getGroup()
        this.getPosts()
      })
    }
    const localItems: any = getUserDetails();
    const userLang = localItems.preferred_language;
    i18next.changeLanguage(userLang.toLowerCase());
  }

  getDataFromLocalStorage = () => {
    let group;
    const group1 = JSON.parse(localStorage.getItem("new_group")!) || {}
    const group2 = JSON.parse(localStorage.getItem("notify_arr")!) || {}
    if (group2.type === "group") {
      group = group2
    } else {
      group = group1
    }
    return group;
  }

  handleOpen = (value: string) => {
    localStorage.removeItem("edit_group")
    if (value !== "CommunityForumWeb") {
      if (value === "AccountGroups") {
        this.getGroups()
      }
      localStorage.setItem("group_id", this.state.group && this.state.group.id)
      this.setState({ openOtherComponent: value }, () => this.getGroup())
    } else {
      window.location.href = value
    }
  }    

  onSearchGroupChangeHandler = (event: any) => {
    this.setState({ groupSearchInput: event.target.value }, () => this.getSearchGroups())
  }

  onLikeHandler = (id: number, isLike: boolean, likeId: any, index: number) => {
    this.setState({ postIndexToReplace: index })
    this.setState({ postIdToReplace: id })
    const body1 = {
      data: {
        attributes: {
          likeable_id: id,
          likeable_type: "BxBlockPosts::Post"
        }
      }
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isLike ?
        `${configJSON.likesApiEndPoint}${'/' + likeId}` :
        `${configJSON.likesApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isLike ? configJSON.deleteApiMethod : configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    if (!isLike) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body1)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  commentBoxVisibilityHandler = (index: number) => {
    this.setState({ commentInput: "" })
    this.setState({ commentIndex: index })
    this.setState({ isCommentEnable: true })
  }

  onMediaModalHandler = (item: any) => {
    this.setState({ isModalMedia: true, modalMediaData: item })
  }

  onMediaModalCloseHandler = () => {
    this.setState({ isModalMedia: false })
  }

  onReplyHandler = (index: number, idx: number) => {
    this.setState({ commentInput: "", isCommentEnable: false, commentIndex: index, replyIndex: idx })
  }

  onRepliedHandler = (index: number, idx: number) => {
    this.setState({ commentIndex: index, repiedIndex: idx, isReplied: true })
  }

  onClickCommentIndex = (index: number) => {
    this.setState({ commentActiveIndex: index, replyIndex: null })
  }

  onClickReplyComment = (index: number) => {
    this.setState({ commentActiveIndex: index, isCommentEnable: false })
  }

  onCommentChange = (event: any) => {
    this.setState({ isCommentActive: true, commentInput: event.target.value })
  }

  onCommentHandler = (id: number, commentId: number | null, index: number) => {
    this.setState({ isCommentActive: false, postIdToReplace: id, postIndexToReplace: index })
    const validId = commentId ? commentId : id
    const type = commentId ? "BxBlockComments::Comment" : "BxBlockPosts::Post"
    const body1 = {
      data: {
        attributes: {
          comment: this.state.commentInput,
          commentable_id: validId,
          commentable_type: type
        }
      }
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body1)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ replyIndex: null })
    return true;
  }

  onReportModalClickHandler = (index: number) => {
    this.setState({ reportIndex: index, isReportModal: !this.state.isReportModal })
  }

  onReportModalCloseHandler = () => {
    if (this.state.isReportModal === true) {
      this.setState({ isReportModal: false })
    }
  }

  handleConfirmModalClose = () => {
    this.setState({ confirmModalOpen: false, reportPostId: "" });
  }

  handleConfirmModalOpen = (id: string) => {
    this.setState({ confirmModalOpen: true, reportPostId: id });
  }

  handleConfirmModalSubmit = () => {
    this.setState({ confirmModalOpen: false });
    this.reportPost(this.state.reportPostId);
  }

  handleReportGroup = () => {
    this.setState({ confirmModalOpen: true, isMoreHorizOpen: false });
  }

  allDownloadHandler = (mediaArr: any) => {
    mediaArr.forEach((mediaItem: any, index: number) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', mediaItem.url, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        const blob = new Blob([xhr.response], { type: mediaItem.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = mediaItem.filename || 'downloaded-file';
        link.click();
      };
      xhr.send();
    })
  }

  reportPost = (id: number | string) => {
    const data = {
      post_id: id
    };
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.reportPostId ? `${configJSON.reportPostApiEndPoint}` : `account_groups/${this.state.group.id}/report_on_group`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  playAudio = () => {
    document.addEventListener('play', function (e) {
      const audio = document.getElementsByTagName('audio');
      for (let item of audio) {
        if (item != e.target) {
          item.pause();
        }
      }
    }, true);
  };

  playVideo = () => {
    document.addEventListener('play', function (e) {
      const video = document.getElementsByTagName('video');
      for (let item of video) {
        if (item != e.target) {
          item.pause();
        }
      }
    }, true);
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  handlePostDetails = (id: number) => {
    this.setState({ postId: id })
    this.getPostsDetails(id);
    this.setState({ hashtagFiltered: [], hashValue: null, isHashTag: false })
  }

  getPostsDetails = (id: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupPostApiEndPoint}/${this.state.group.id}/posts/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlePostDetailsBack = () => {
    this.setState({ postDetails: { id: 0, type: '', attributes: null }, hashtagFiltered: [], isHashTag: false, hashValue: null })
    this.getPosts();
  }

  getGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getApiEndPoint}/${this.state.group?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getGroupDetails = (group: any, joinFrom: any) => {
    localStorage.setItem("new_group", JSON.stringify(group))
    if (this.state.openOtherComponent === "AccountGroups") {
      localStorage.setItem("join_from", joinFrom)
      window.location.href = "AccountGroups"
    } else {
      const isMember = group.attributes.accounts.map((item: any) => item.account_id.toString())
        .includes(this.state.userId && this.state.userId.toString())
      this.setState({ group, openOtherComponent: "AccountGroups", isMember, joinFrom, posts: [] }, () => {
        this.getPosts()
        this.getGroup()
        this.getGroups()
      })
    }
  }

  getHandleBack = () => {
    this.setState({openOtherComponent : 'AccountGroups'})
  }

  getGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getApiEndPoint}?[data][attributes][page]=1&[data][attributes][per_page]=200`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getRecentGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecentGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.recentGroupsApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSearchGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchGroupEndPoint}?data[attributes][name]=${this.state.groupSearchInput}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getHashtags = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hashtagsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hashtagsApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  fetchData = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => this.getPosts())
  }

  getPosts = () => {
    this.setState({ isPostLoadings: false })
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `group_posts/${this.state.group?.id}/all_posts?[data][attributes][page]=1&[data][attributes][per_page]=200`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getNowTrending = () => {
    const group = JSON.parse(localStorage.getItem("new_group")!) || {}
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.topTrendingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `group_posts/${group?.id}/group_trending_post?[data][attributes][page]=1&[data][attributes][per_page]=5`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleJoinGroup = (group: any, joinFrom: any) => {
    this.setState({ groupId: group.id, status: "requested", joinFrom })
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.joinGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      group.attributes && group.attributes.group_type === "public" ? `account_groups/join_public_group/${group.id}` : `account_groups/send_request/${group.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onShareInputHandler = (postUrl: string) => {
    const splitURL = postUrl.split('/')
    const postId = splitURL[splitURL.length - 1];
    const url = window.location.origin + `${'/CommunityForumWeb/' + postId}`
    navigator.clipboard?.writeText(url);
    this.setState({
      showSnackbar: true,
      message: "Copied!",
      severity: "success",
    });
  }

  onDeleteHandler = (groupId: any, postId: number) => {
    this.getHashtags()
    const deletedPosts = this.state.posts.filter((item: any) => item.attributes.id !== postId)
    this.setState({ posts: deletedPosts, hashtagFiltered: [], hashValue: null, isHashTag: false })
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `group_posts/${groupId}/posts/${postId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postModalHandlers = () => {
    this.setState({ isEdit: false, isOpens: !this.state.isOpens, isAttachOpens: false, userPostInterests: [], existedInterests: [], isLoadings: false })
    this.setState({ postMedias: [] })
    this.setState({ postInputs: '' })
    this.setState({ allMediaArrays: [] })
    this.setState({ mediaIndexes: [] })
    this.getUserInterests();
  }

  getUserInterests = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserInterest = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userInterestAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onEditHandler = (post: any) => {
    this.getUserInterests();
    this.filterInterestEdits(post.attributes.post_interest)
    this.setState({
      isEdit: true,
      editPostId: post.id,
      isOpens: !this.state.isOpens,
      postInputs: post.attributes.description,
      postMedias: [],
      existedMedia: [...post.attributes.images, ...post.attributes.videos, ...post.attributes.audios, ...post.attributes.documents]
    })
  }

  filterInterestEdits = (array: any) => {
    if (array) {
      let arr: any = [];

      array.forEach((intrst: any) => {
        arr.push({
          cat: intrst.interest_category,
          key: intrst.name,
          id: intrst.id
        });
      });

      this.setState({ existedInterests: arr })
      return arr;
    } else {
      return []
    }
  }

  handleAttachModals = () => {
    this.setState({ isAttachOpens: !this.state.isAttachOpens })
  }

  postMediaHandlers = (e: any, type: string) => {
    const multipleItemss = e.target.files;
    let multipleItemsArrays = [...multipleItemss]
    let resdatas = this.handlePostFilesValidations(multipleItemsArrays, type);
    const finalRess = multipleItemsArrays.filter((val: any, indx: number) => (!resdatas.includes(indx)));
    this.readFilePhotoPreviews(finalRess);
    this.setState({ allMediaArrays: [...this.state.allMediaArrays, ...finalRess] })
    if (resdatas.length > 0) {
      type === "image" &&
        this.setState({
          showSnackbar: true,
          message: t("Only png, jpeg and jpg file can be upload"),
          severity: "error",
        });
      type === "video" &&
        this.setState({
          showSnackbar: true,
          message: t("Only mp4, mov, wmv, flv, avi, mkv and webm file can be upload"),
          severity: "error",
        });
      type === "audio" &&
        this.setState({
          showSnackbar: true,
          message: t("Only mp3 and mpeg file can be upload"),
          severity: "error",
        });
      type === "document" &&
        this.setState({
          showSnackbar: true,
          message: t("Only doc, docx, csv, pdf, and msword file can be upload"),
          severity: "error",
        });

    }
    e.target.value = null;
  }

  handlePostFilesValidations = (fileState: any, type: string) => {
    let isValids = true;
    let fileArrays = [...fileState]
    let removeIndxs: any = [];
    const image_extensions = ["image/png", "image/jpeg", "image/jpg"];
    const video_extensions = ["video/mp4", "video/mov", "video/wmv", "video/flv", "video/avi", "video/mkv", "video/webm"];
    const audio_extensions = ["audio/mp3", "audio/mpeg"];
    const doc_extensions = ["application/doc", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/csv", "application/pdf", "application/msword"];
    switch (type) {

      case "image":
        fileArrays.forEach((file: any, i: number) => {
          if (!image_extensions.includes(file.type)) {
            removeIndxs.push(i);
          }
        });
        break;

      case "video":
        fileArrays.forEach((file: any, i: number) => {
          if (!video_extensions.includes(file.type)) {
            removeIndxs.push(i);
          }
        });
        break;

      case "audio":
        fileArrays.forEach((file: any, i: number) => {
          if (!audio_extensions.includes(file.type)) {
            removeIndxs.push(i);
          }
        });
        break;

      case "document":
        fileArrays.forEach((file: any, i: number) => {
          if (!doc_extensions.includes(file.type)) {
            removeIndxs.push(i);
          }
        });
        break;

    }
    return removeIndxs;
  };

  readFilePhotoPreviews = (FileList: any) => {
    let fileArrays = [...FileList]
    let tempMediaArrays: any = [];

    fileArrays.forEach((file) => {
      const readers = new FileReader();
      const promises = new Promise((resolve) => {
        readers.onload = (event) => {
          resolve(event.target?.result);
        };
      });

      readers.readAsDataURL(file);
      tempMediaArrays.push(promises);
    });

    Promise.all(tempMediaArrays).then((results) => {
      this.setState({ postMedias: [...this.state.postMedias, ...results] });
    });
  };

  handleCloseAttachmentModals = () => {
    if (this.state.isAttachOpens === true) {
      this.setState({ isAttachOpens: false })
    }
  }

  postInputHandlers = (e: any) => {
    this.setState({ postInputs: e.target.value })
  }

  onFilteredHandlers = (idx: number) => {
    const filteredMedias = this.state.allMediaArrays.filter((i: any, id: number) => id !== idx)
    this.setState({ allMediaArrays: filteredMedias })
    const shownMediaFilters = this.state.postMedias.filter((i: any, id: number) => id !== idx);
    this.setState({ postMedias: shownMediaFilters })
  }

  handleOpenInterests = () => {
    document.body.classList.add('my-class')
    this.setState({ openInterests: true })
  }

  handleCloseInterests = () => {
    document.body.classList.remove('my-class')
    this.setState({ openInterests: false })
  }

  // postInterestHandlers = (e: any) => {
  //   this.setState({ userPostInterests: e.target.value })
  // }

  handleCloseSnackbars = () => {
    this.setState({
      showSnackbar: false,
      severity: "",
      message: "",
    });
    return true;
  };

  postApiHandlers = (groupId: any) => {
    this.setState({ isLoadings: true });
    let formData: any = new FormData();
    formData.append('data[attributes][description]', this.state.postInputs);
    formData.append('data[attributes][interest_ids][]', this.state.userPostInterests)
    for (let item of this.state.allMediaArrays) {
      item.type.includes("image") && formData.append('data[attributes][images][]', item);
      item.type.includes("video") && formData.append('data[attributes][videos][]', item);
      item.type.includes("audio") && formData.append('data[attributes][audios][]', item);
      item.type.includes("application") && formData.append('data[attributes][documents][]', item);
    }

    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCreateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `group_posts/${groupId}/posts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onExistedMediaHandlers = (itemId: number, idx: number) => {
    this.setState({ mediaIndexes: [...this.state.mediaIndexes, itemId] })
    let filteredMedia = this.state.existedMedia.filter((i: any, id: number) => id !== idx)
    this.setState({ existedMedia: filteredMedia })
  }

  onEditApiHandlers = (groupId: any, editPostId: any) => {
    this.setState({ isLoadings: true });
    this.onAttachDeleteHandlers(editPostId)
    let formData: any = new FormData();
    formData.append('data[attributes][description]', this.state.postInputs)
    this.state.userPostInterests.map((i: any) => {
      formData.append('data[attributes][interest_ids][]', i)
    })
    this.state.allMediaArrays.map((item: any, idx: number) => {
      this.state.allMediaArrays[idx].type.includes('video') && formData.append("data[attributes][new_videos][]", this.state.allMediaArrays[idx]);
      this.state.allMediaArrays[idx].type.includes('image') && formData.append("data[attributes][new_images][]", this.state.allMediaArrays[idx]);
      this.state.allMediaArrays[idx].type.includes('application') && formData.append("data[attributes][new_documents][]", this.state.allMediaArrays[idx]);
      this.state.allMediaArrays[idx].type.includes('audio') && formData.append("data[attributes][new_audios][]", this.state.allMediaArrays[idx]);
    })

    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `group_posts/${groupId}/posts/${editPostId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onAttachDeleteHandlers = (editPostId: any) => {
    const body = {
      attachment_id: this.state.mediaIndexes
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.attachDeleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `posts/remove_attachment/${editPostId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSearchGroupPost = (event: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchGroupPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `group_posts/${this.state.group?.id}/search_group_posts?data[attributes][description]=${event.target.value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleMoreHoriz = () => {
    this.setState({ isMoreHorizOpen: !this.state.isMoreHorizOpen })
  }

  handleEditGroup = () => {
    localStorage.setItem("edit_group", JSON.stringify(this.state.group))
    this.setState({ openOtherComponent: "CreateNewGroup", isMoreHorizOpen: false })
  }

  handleLeaveGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/${this.state.group?.id}/leave_group`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postInterestRemoves = (selectedList: any) => {
    let filteredList = selectedList.map((i: any) => i.id)
    this.setState({ userPostInterests: filteredList })
  }

  postInterestHandlers = (selectedList: any) => {
    let filteredList = selectedList.map((i: any) => i.id)
    this.setState({ userPostInterests: filteredList })
  }

  handleHashTags = (tags: string) => {
    let filteredWithTags = this.state.posts.filter((i: any) => i.attributes.description.includes(`#${tags}`))
    this.setState({ hashValue: tags, isHashTag: true, hashtagFiltered: filteredWithTags })
  }

  onCommentDeleteHandler = (commentId: number) => {
    this.setState({ isLoadings: true })
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentDeleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentApiEndPoint}${'/' + commentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDeleteGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/groups/${this.state.group?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostDetailToReplace = (id: number | string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postDetailToReplaceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupPostApiEndPoint}/${this.state.group.id}/posts/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.fetchUser = "account/accounts/specific_account";
exports.userMedia = "account/user_images";
exports.userRecentActivitiesApiEndPoint = "account/recent_activity";
exports.fetchInterests = "bx_block_interests/user_interests";
exports.fetchMyPosts = "posts/my_posts";
exports.trendingPosts = "posts/trending_post?[data][attributes][page]=1&[data][attributes][per_page]=5";
exports.myGroups = "account_groups/my_groups"
exports.joinedGroups = "account_groups/joined_groups"
exports.createGroup = "account_groups/groups"
exports.leaveGroupApiEndPoint = "account_groups"
exports.groupMmberEndPoint = "account_groups"
exports.groupMmberReportEndPoint = "account_groups"
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.uploadProfilePhoto="Upload Profile Photo";
exports.uploadCoverPhoto="Upload Cover Photo";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.upload = "Upload";
exports.likesApiEndPoint = "like/likes";
exports.commentApiEndPoint = "comments";
exports.profileUpdate = "account/update";
exports.phtoLibrary = "photo_library/photo_library";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.deleteAPiMethodType = "DELETE";
exports.reportApiEndPoint = "posts/report_on_post";
exports.shareToResourceHubEndPoint = "resources/share_post";
exports.userGroupsApiEndPoint = "account_groups/user_groups";
// Customizable Area End


import React from "react";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  Input,
  Divider,
} from "@material-ui/core";
import { LikeButton } from "../../../../components/src/LikeButton";
import { MediaGrid, MediaWithGrid, MediaWithoutGrid } from "./media.web";
import SendIcon from '@material-ui/icons/Send';
import { t } from "i18next";

export const Media = ({ length, arr, onMediaModalHandler, playVideo, playAudio }: any) => {
  if (length >= 5) {
    return arr.slice(0, 4).map((item: any, idx: number) => (
      <MediaGrid mediaArr={arr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else if (length === 1) {
    return arr.map((item: any, idx: number) => (
      <MediaWithoutGrid mediaArr={arr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else {
    return arr.map((item: any, idx: number) => (
      <MediaWithGrid mediaArr={arr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  }
}

export const Posts = ({ posts, mediaArr, commentInput, onCommentChange, onCommentHandler, onLikeHandler, commentBoxVisibilityHandler,
  isCommentEnable, commentIndex, replyIndex, commentActiveIndex, isReplied, repiedIndex, isCommentActive, isReportModal, reportIndex, authorId,
  onRepliedHandler, onReplyHandler, onClickReplyComment, onClickCommentIndex, onReportModalClickHandler, allDownloadHandler, handleConfirmModalOpen,
  onMediaModalHandler, playVideo, playAudio, onShareInputHandler, handlePostDetails, onDeleteHandler, groupId, onEditHandler, isMember, fetchData,
  pageNumber, totalPages, onCommentDeleteHandler
}: any) => {

  const hashtags=[{name: 'Gardening'},{name: 'Food'}]
  return (
    <>
      {posts && posts.length > 0 && posts.map((item: any, index: number) => {
        let mediaArray = [...item.attributes.images, ...item.attributes.videos, ...item.attributes.audios, ...item.attributes.documents]
        let arr = mediaArray
        return (
          <>
            <Box className="recentPostCards" key={item.id}>
              <Box className="post_container">
                <Avatar className="post_avatar_image" src={item.attributes.author_image} />
                <Grid container className="post_main_info"  xs={8} sm={10} md={10} lg={10} xl={10}>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="post_author_name" onClick={() => handlePostDetails(item.id)}>
                      {`${item.attributes.author_name}`}
                    </Typography>
                    {authorId == item.attributes.author_id && <Typography
                      component="span"
                      variant="body2"
                      className="author_text"
                    >
                      {`(${t('Author')})`}
                    </Typography>}
                  </Box>
                  <Typography data-test-id="test-post-id-two"
                    onClick={() => handlePostDetails(item.id)}
                    className="post_desc_text">
                    {item.attributes.description}
                  </Typography>
                  <Grid container style={{ display: 'flex', marginTop: '12px' }}>
                    {hashtags.map((ele, index) => {
                      return (
                        <Box className="post_hashtag_container">
                          <Typography className="post_hashtag_text">
                            {`#${ele.name}`}
                          </Typography>
                        </Box>
                      )
                    })
                    }
                  </Grid>
                  {
                    item.attributes.description.length > 0 &&
                    <div test-id="translation-id" className="see_trans_chip">
                      <div className="see_trans_chip_container">
                        <Typography className="see_trans_chip_text">
                          {t('See translation')}
                        </Typography>
                      </div>
                    </div>
                  }

                </Grid>
                <Grid container className="post_created_container"  xs={4} sm={2} md={2} lg={2} xl={2} >
                  <Typography className="post_created_at_text">{item.attributes.created_at}</Typography>
                  <div data-test-id="report-btn" className={`post_three_dots ${typeof reportIndex} ${typeof item.id} ${isReportModal}`} onClick={() => onReportModalClickHandler(item.id)} style={{ position: "relative" }}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.09863 10.3516C4.99863 10.3516 4.09863 11.2516 4.09863 12.3516C4.09863 13.4516 4.99863 14.3516 6.09863 14.3516C7.19863 14.3516 8.09863 13.4516 8.09863 12.3516C8.09863 11.2516 7.19863 10.3516 6.09863 10.3516ZM18.0986 10.3516C16.9986 10.3516 16.0986 11.2516 16.0986 12.3516C16.0986 13.4516 16.9986 14.3516 18.0986 14.3516C19.1986 14.3516 20.0986 13.4516 20.0986 12.3516C20.0986 11.2516 19.1986 10.3516 18.0986 10.3516ZM12.0986 10.3516C10.9986 10.3516 10.0986 11.2516 10.0986 12.3516C10.0986 13.4516 10.9986 14.3516 12.0986 14.3516C13.1986 14.3516 14.0986 13.4516 14.0986 12.3516C14.0986 11.2516 13.1986 10.3516 12.0986 10.3516Z" fill="#64748B" />
                        </svg>

                        {isReportModal && reportIndex == item.id &&
                          <Box test-id="reportbox01" className='report-modal'>
                            <Typography data-test-id="download-id" className='report-text' onClick={() => allDownloadHandler(arr)}>{t("Download")}</Typography>
                            {
                              item.attributes.author_id == authorId && isMember &&
                              <>
                                <Divider style={{ width: "100%" }} />
                                <Typography data-test-id="edit-id" className='report-text' onClick={() => onEditHandler(item)}>{t("Edit")}</Typography>
                                <Divider style={{ width: "100%" }} />
                                <Typography data-test-id="delete-id" className='report-text'
                                  onClick={() => onDeleteHandler(groupId, item.attributes.id)}>{t("Delete")}</Typography>
                              </>
                            }

                            {item.attributes.author_id != authorId &&
                              <>
                                <Divider style={{ width: "100%" }} />
                                <Typography data-test-id="report-id" onClick={() => handleConfirmModalOpen(item.id)} className='report-text'>{t("Report")}</Typography>
                              </>
                            }
                          </Box>}
                      </div>
                </Grid>

              </Box>
              <div className='postImageContainer'>
                <Grid container spacing={1} style={{ justifyContent: "center", padding: "0 20px" }}>
                  <Media length={mediaArray.length} arr={mediaArray} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                </Grid>
              </div>

            </Box>
          </>
        )
      })}
      {posts === undefined && (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Typography>{t("No posts found")}</Typography>
        </div>
      )}
    </>
  )
}
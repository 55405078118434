import React, { useMemo } from "react";
// Customizable Area Start
import { Typography, Avatar, Button, Paper, Box, Divider, Chip, Badge } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { Add } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { GroupDetail } from './GroupDetail.web'
import { t } from "i18next";
import { user5 } from "../assets";
import PendingRequestModal from "../../../requestmanagement/src/components/PendingRequestModal.web";

interface UserGroupsProps {
    myGroups: any;
    user: any;
    joinedGroups: any;
    currentIndex: number | null;
    navigation: any;
    isMenu: boolean;
    isMenu2: boolean;
    onMoreClickHandler: any;
    onMore2ClickHandler: any;
    onLeaveHandler: any;
    getGroupMembersDetail: any;
    groupMembers: any;
    isMember: boolean;
    members: number;
    groupName: string;
    userId: string | number;
    onMemberViewHandler: any;
    handleCreateGroupRedirection: any;
    onMoreIconHandler: any;
    handleMoreMember: any;
    handleMakeMember: any;
    handleMemberReport: any;
    membersIndex: number | string;
    handleRemoveMemberApi: any;
    isKabab: boolean;
}

const UserGroups = (props: UserGroupsProps) => {
    const { onMoreIconHandler, onMore2ClickHandler, isMenu2, membersIndex, myGroups, joinedGroups, user, currentIndex, groupMembers, groupName, members, isKabab, userId, isMenu, isMember, handleRemoveMemberApi, handleMakeMember, onMemberViewHandler, handleMoreMember, onMoreClickHandler, handleCreateGroupRedirection, handleMemberReport } = props
    const [valueTab, setValueTab] = React.useState(0);
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        const updatedValueTab = newValue;
        setValueTab(updatedValueTab);
    };
    const groupList = useMemo(() => {
        if (myGroups.length || joinedGroups.length) {
            if (valueTab === 0) {
                return myGroups;
            } else {
                return joinedGroups;

            }
        } else {
            return []
        }
    }, [myGroups, user, valueTab, joinedGroups])

    const handleGroupDetails = (groupData: any) => {
        localStorage.setItem('new_group', JSON.stringify(groupData))
        window.location.href = "AccountGroups";
    }

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
    };

    const memberList = [
        {
            photo: require('../../assets/user1.png'),
            name: 'Cameron Williamson',
            coversations: [{}, {}, {}]
        },
        {
            photo: require('../../assets/user2.png'),
            name: 'Jacob Jones'
        },
        {
            photo: require('../../assets/user1.png'),
            name: 'Cameron Williamson',
            coversations: [{}, {}, {}]
        },
        {
            photo: require('../../assets/user2.png'),
            name: 'Jacob Jones'
        },
        {
            photo: require('../../assets/user1.png'),
            name: 'Cameron Williamson',
            coversations: [{}, {}, {}]
        },
        {
            photo: require('../../assets/user2.png'),
            name: 'Jacob Jones'
        },
        {
            photo: require('../../assets/user1.png'),
            name: 'Cameron Williamson',
            coversations: [{}, {}, {}]
        },
        {
            photo: require('../../assets/user2.png'),
            name: 'Jacob Jones'
        },
    ]
    return (
        <>
            {
                isMember ?
                    <Paper elevation={1} style={{ border: 'none', borderRadius: '8px' }}>
                        <GroupDetail
                            userId={userId}
                            isKabab={isKabab}
                            members={members}
                            groupName={groupName}
                            membersIndex={membersIndex}
                            groupMembers={groupMembers}
                            handleMakeMember={handleMakeMember}
                            handleMoreMember={handleMoreMember}
                            handleMemberReport={handleMemberReport}
                            onMemberViewHandler={onMemberViewHandler}
                            handleRemoveMemberApi={handleRemoveMemberApi}
                        />
                    </Paper>
                    :
                    <Paper elevation={1} style={{ border: 'none', borderRadius: '8px' }} >
                        <Box sx={{ width: '100%', border: 'none' }}>
                            <Box className='top-box-container'>
                                <Tabs test-id="groups-tabs-id" value={valueTab} onChange={handleChangeTab} className="tab_top_container">
                                    <Tab label={t('My Groups')} className='text_tab_first' />
                                    <Tab label={t('Explore Groups')} className="text_tab_second" />
                                </Tabs>
                                <Box className="search_create_container">
                                    <SearchIcon className="create_search_icon" />
                                    <Button data-test-id="text_btn_create"
                                        className="text_btn_create"
                                        onClick={handleCreateGroupRedirection}
                                    >
                                        {t('Create new group')}
                                    </Button>
                                    <Badge
                                        className="plus-create-icon"
                                        onClick={handleCreateGroupRedirection}
                                    >
                                        <Add style={{ color: '#5497FF' }} />
                                    </Badge>
                                </Box>
                            </Box>
                        </Box>

                        <Box style={{ border: 'none' }}>
                            {
                                groupList.length > 0 ?
                                    groupList.map((ele: any, index: number) => {
                                        return (
                                            <List className="group_container" key={index}>
                                                <ListItem className="individual_group_container">
                                                    <ListItemAvatar
                                                        className="group_photo_container">
                                                        <Avatar
                                                            className="group_photo_main_container"
                                                            test-id="group-icon" alt="Remy Sharp" src={ele.attributes.icon_image}
                                                            onClick={() => {
                                                                if(valueTab===0){
                                                                    handleGroupDetails(ele)
                                                                }
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        test-id="group-name"
                                                        onClick={() => {
                                                            if(valueTab===0){
                                                                handleGroupDetails(ele)
                                                            }
                                                        }}
                                                        primary={
                                                            <Box style={{ display: 'flex' }}>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className="text_first_group"
                                                                >
                                                                    {ele.attributes.name}
                                                                </Typography>
                                                                {userId == ele.attributes.created_by_id && <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className="text_third_group"
                                                                >
                                                                    {`(${t('Admin')})`}
                                                                </Typography>}
                                                            </Box>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className="text_first_group"

                                                                >
                                                                    {ele.attributes.group_description}

                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    <Box className="grp_info">
                                                        <><Typography
                                                            component="span"
                                                            variant="body2"
                                                            style={{ color: '#475569' }}
                                                        >
                                                        </Typography>
                                                            <Chip
                                                                onClick={() => {
                                                                    onMoreIconHandler(index)
                                                                    setOpen(true)
                                                                }}
                                                                className="pending_requests_chip"
                                                                label={`99+ Requests`}
                                                            /></>
                                                        {currentIndex === index && <PendingRequestModal
                                                            open={open}
                                                            data-test-id={'pending-modal'}
                                                            navigation={props.navigation}
                                                            modalCloseFn={handleClose}
                                                            membersList={memberList} />}
                                                        <Box className="pending-count_container">
                                                            <Typography
                                                                className="pending_count_number"
                                                            >
                                                                {`99+`}
                                                            </Typography>
                                                        </Box>
                                                        <Box className="pending_requests_icon">
                                                            <img src={user5}
                                                                style={{
                                                                    height: '20px',
                                                                    width: '20px'
                                                                }}
                                                                onClick={() => {
                                                                    onMoreIconHandler(index)
                                                                    setOpen(true)
                                                                }}
                                                                data-test-id="pending_requests_icon"
                                                            />
                                                        </Box>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className="text_member"
                                                        >
                                                            {ele.attributes.group_members} {ele.attributes.group_members > 1 ? t('Members') : t('Member')}
                                                        </Typography>
                                                        <Badge
                                                            className="grp-people-icon"
                                                        >
                                                            <PeopleAltIcon style={{ color: '#64748B' }} />
                                                        </Badge>
                                                        {valueTab === 0 && <Badge
                                                            test-id="more-id"
                                                            className={isMenu && currentIndex === index ? "HorizIcon_cls_clicked " : "Horizicon_cls"}
                                                            onClick={() => onMoreClickHandler(index, ele.id)}
                                                        >
                                                            <MoreHorizIcon style={{ color: '#64748B' }} />
                                                        </Badge>}
                                                        {valueTab === 1 && <Badge
                                                            test-id="more-id2"
                                                            className={isMenu2 && currentIndex === index ? "HorizIcon_cls_clicked " : "Horizicon_cls"}
                                                            onClick={() => onMore2ClickHandler(index, ele.id)}
                                                        >
                                                            <MoreHorizIcon style={{ color: '#64748B' }} />
                                                        </Badge>}
                                                        {
                                                            isMenu && currentIndex === index && valueTab === 0 &&
                                                            <Box className='group_menu_items grp-kabab'>
                                                                <div style={{ display: 'flex', paddingRight: '20px', alignItems: 'center' }}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <mask id="mask0_11538_22743" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                                                            <rect width="16" height="16" fill="#D9D9D9" />
                                                                        </mask>
                                                                        <g mask="url(#mask0_11538_22743)">
                                                                            <path d="M7.99984 10.666C8.83317 10.666 9.5415 10.3743 10.1248 9.79102C10.7082 9.20768 10.9998 8.49935 10.9998 7.66602C10.9998 6.83268 10.7082 6.12435 10.1248 5.54102C9.5415 4.95768 8.83317 4.66602 7.99984 4.66602C7.1665 4.66602 6.45817 4.95768 5.87484 5.54102C5.2915 6.12435 4.99984 6.83268 4.99984 7.66602C4.99984 8.49935 5.2915 9.20768 5.87484 9.79102C6.45817 10.3743 7.1665 10.666 7.99984 10.666ZM7.99984 9.46602C7.49984 9.46602 7.07484 9.29102 6.72484 8.94102C6.37484 8.59102 6.19984 8.16602 6.19984 7.66602C6.19984 7.16602 6.37484 6.74102 6.72484 6.39102C7.07484 6.04102 7.49984 5.86602 7.99984 5.86602C8.49984 5.86602 8.92484 6.04102 9.27484 6.39102C9.62484 6.74102 9.79984 7.16602 9.79984 7.66602C9.79984 8.16602 9.62484 8.59102 9.27484 8.94102C8.92484 9.29102 8.49984 9.46602 7.99984 9.46602ZM7.99984 12.666C6.37761 12.666 4.89984 12.2132 3.5665 11.3077C2.23317 10.4021 1.2665 9.18824 0.666504 7.66602C1.2665 6.14379 2.23317 4.9299 3.5665 4.02435C4.89984 3.11879 6.37761 2.66602 7.99984 2.66602C9.62206 2.66602 11.0998 3.11879 12.4332 4.02435C13.7665 4.9299 14.7332 6.14379 15.3332 7.66602C14.7332 9.18824 13.7665 10.4021 12.4332 11.3077C11.0998 12.2132 9.62206 12.666 7.99984 12.666ZM7.99984 11.3327C9.25539 11.3327 10.4082 11.0021 11.4582 10.341C12.5082 9.6799 13.3109 8.78824 13.8665 7.66602C13.3109 6.54379 12.5082 5.65213 11.4582 4.99102C10.4082 4.3299 9.25539 3.99935 7.99984 3.99935C6.74428 3.99935 5.5915 4.3299 4.5415 4.99102C3.4915 5.65213 2.68873 6.54379 2.13317 7.66602C2.68873 8.78824 3.4915 9.6799 4.5415 10.341C5.5915 11.0021 6.74428 11.3327 7.99984 11.3327Z" fill="#475569" />
                                                                        </g>
                                                                    </svg>
                                                                    <Typography test-id="down-click-id" className='group_option_text'
                                                                    >{t('View')}</Typography>
                                                                </div>
                                                                <Divider style={{ width: "100%" }} />
                                                                <div style={{ display: 'flex', paddingRight: '20px', alignItems: 'center' }}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <mask id="mask0_11538_22747" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                                                            <rect width="16" height="16" fill="#D9D9D9" />
                                                                        </mask>
                                                                        <g mask="url(#mask0_11538_22747)">
                                                                            <path d="M1.3335 16.0007V13.334H14.6668V16.0007H1.3335ZM4.00016 10.6673H4.9335L10.1335 5.48398L9.1835 4.53398L4.00016 9.73398V10.6673ZM2.66683 12.0007V9.16732L10.1335 1.71732C10.2557 1.5951 10.3974 1.50065 10.5585 1.43398C10.7196 1.36732 10.8891 1.33398 11.0668 1.33398C11.2446 1.33398 11.4168 1.36732 11.5835 1.43398C11.7502 1.50065 11.9002 1.60065 12.0335 1.73398L12.9502 2.66732C13.0835 2.78954 13.1807 2.93398 13.2418 3.10065C13.3029 3.26732 13.3335 3.43954 13.3335 3.61732C13.3335 3.78398 13.3029 3.94787 13.2418 4.10898C13.1807 4.2701 13.0835 4.41732 12.9502 4.55065L5.50016 12.0007H2.66683Z" fill="#475569" />
                                                                        </g>
                                                                    </svg>
                                                                    <Typography className='group_option_text'
                                                                    >{t('Edit')}</Typography>
                                                                </div>
                                                                <Divider style={{ width: "100%" }} />
                                                                <div style={{ display: 'flex', paddingRight: '20px', alignItems: 'center' }}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <mask id="mask0_11538_22751" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                                                            <rect width="16" height="16" fill="#D9D9D9" />
                                                                        </mask>
                                                                        <g mask="url(#mask0_11538_22751)">
                                                                            <path d="M4.6665 14C4.29984 14 3.98595 13.8694 3.72484 13.6083C3.46373 13.3472 3.33317 13.0333 3.33317 12.6667V4H2.6665V2.66667H5.99984V2H9.99984V2.66667H13.3332V4H12.6665V12.6667C12.6665 13.0333 12.5359 13.3472 12.2748 13.6083C12.0137 13.8694 11.6998 14 11.3332 14H4.6665ZM11.3332 4H4.6665V12.6667H11.3332V4ZM5.99984 11.3333H7.33317V5.33333H5.99984V11.3333ZM8.6665 11.3333H9.99984V5.33333H8.6665V11.3333Z" fill="#475569" />
                                                                        </g>
                                                                    </svg>
                                                                    <Typography className='group_option_text'
                                                                    >{t('Delete')}</Typography>
                                                                </div>

                                                            </Box>
                                                        }
                                                        {isMenu2 && currentIndex === index && valueTab === 1 &&
                                                            <Box className='group_menu_items grp-kabab'>
                                                                <div style={{ display: 'flex', paddingRight: '20px', alignItems: 'center' }}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.0002 8.66732H8.66683V12.0007C8.66683 12.3673 8.36683 12.6673 8.00016 12.6673C7.6335 12.6673 7.3335 12.3673 7.3335 12.0007V8.66732H4.00016C3.6335 8.66732 3.3335 8.36732 3.3335 8.00065C3.3335 7.63398 3.6335 7.33398 4.00016 7.33398H7.3335V4.00065C7.3335 3.63398 7.6335 3.33398 8.00016 3.33398C8.36683 3.33398 8.66683 3.63398 8.66683 4.00065V7.33398H12.0002C12.3668 7.33398 12.6668 7.63398 12.6668 8.00065C12.6668 8.36732 12.3668 8.66732 12.0002 8.66732Z" fill="#475569" />
                                                                    </svg>
                                                                    <Typography test-id="down-click-id" className='group_option_text'
                                                                    >{t('Join Group')}</Typography>
                                                                </div>
                                                            </Box>}
                                                    </Box>
                                                </ListItem>
                                                {index < (groupList.length - 1) && <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div style={{
                                                        margin: '4px 0',
                                                        backgroundColor: '#CBD5E1',
                                                        height: '1px',
                                                        width: '95%',
                                                        border: 'none'
                                                    }} />
                                                </div>}
                                            </List>
                                        )
                                    }) :
                                    (
                                        <Typography style={{ textAlign: 'center' }}>
                                            {t('No members found')}
                                        </Typography>
                                    )
                            }
                        </Box>
                    </Paper>
            }
        </>

    );
};


// Customizable Area End

export default UserGroups;

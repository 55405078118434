import React from 'react';
import {
  // Customizable Area Start
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
  Paper,
  Portal
} from "@material-ui/core";

// Customizable Area Start
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import RightBar from '../../../components/src/RightBar.web';
import "../assets/Community.scss";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MediaSlider from './components/MediaSlider.web';
import AllPostDetails from './components/AllPostDetails.web';
import MiddleContainer from './components/MiddleContainer.web';
import SearchBlock from './components/SearchBlock.web';
import { ResponsiveGroups } from './components/ResponsiveGroups.web';
import Multiselect from 'multiselect-react-dropdown';
import { t } from 'i18next';
// Customizable Area End

import Header from '../../../components/src/Header.web';
import CommunityForumController, {
  Props
} from "./CommunityForumController";
import HashTag from './components/HashTag.web';

import ConfirmationBox from '../../../components/src/ConfirmationBox.web';
import UserProfileDetails from './components/UserProfileDetails.web';
import { NoSearchFound } from './components/NoSearchFound.web';
import ModalContainer from './components/ModalContainer.web';
import SideGroupPage from './components/SideGroupPage.web';
import { PostButton } from './components/PostButton.web';
import { selfie } from './assets';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  background: '#fff',
  border: "2px solid #fff",
  borderRadius: "8px",
  maxHeight: '100%',
  height: 'auto',
  "@media(max-width: 575px)": {
    width: '100% !important',
    top: 0,
    transform: 'unset',
    left: 0,
  },
  "@media(max-width: 768px)": {
    width: '85%',
  }
}

export default class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.isModalMedia && <MediaSlider item={this.state.modalMediaData} onClose={this.onMediaModalCloseHandler} allDownloadHandler={this.allDownloadHandler} />}
        {
          <>
            <Header notifyCount={Number(localStorage.getItem("notification_unread_count"))} userSearchProfile={this.state.userSearchProfile} handleBackHomeEmpty={this.handleBackHomeEmpty} nosearchFound={this.state.noFoundSearch} noSerachFoundHandle={this.noSerachFoundHandle} type={"communityForum"} searchKeyword={this.state.searchKeyword} handleSearchChange={this.handleSearchChange} navigation={this.props.navigation} profileImage={this.state.userDetails.photo} />
            {this.state.userSearchProfile?.attributes && !this.state.postDetails?.attributes ?
              <>
                <UserProfileDetails handlePostDetails={this.handlePostDetails} state={this.state} />
              </>
              :
              <>
                {

                  this.state.noFoundSearch === true ?
                    <NoSearchFound />
                    :
                       <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }} onClick={this.onReportModalCloseHandler}>

                        <Grid container className='groups_cotainer' >
                          {
                            (this.state.hashtagFiltered.length > 0 || this.state.postDetails?.attributes) ?
                              <MiddleContainer

                                state={this.state}
                                playVideo={this.playVideo}
                                playAudio={this.playAudio}
                                onLikeHandler={this.onLikeHandler}
                                onEditHandler={this.onEditHandler}
                                authorId={this.state.userDetails.id}
                                onReplyHandler={this.onReplyHandler}
                                onDeleteHandler={this.onDeleteHandler}
                                onCommentChange={this.onCommentChange}
                                onCommentHandler={this.onCommentHandler}
                                onRepliedHandler={this.onRepliedHandler}
                                handlePostDetails={this.handlePostDetails}
                                allDownloadHandler={this.allDownloadHandler}
                                onShareInputHandler={this.onShareInputHandler}
                                onMediaModalHandler={this.onMediaModalHandler}
                                onClickReplyComment={this.onClickReplyComment}
                                onClickCommentIndex={this.onClickCommentIndex}
                                handlePostDetailsBack={this.handlePostDetailsBack}
                                onCommentDeleteHandler={this.onCommentDeleteHandler}
                                handleConfirmModalOpen={this.handleConfirmModalOpen}
                                onReportModalClickHandler={this.onReportModalClickHandler}
                                commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
                                handleTransBackToOriginal={this.handleTransBackToOriginal}
                                handleTranslation={this.handleTranslation}
                                onrhDescriptionChange={this.onrhDescriptionChange}
                                onrhLanguageChange={this.onrhLanguageChange}
                                handleOnShareToResourceHub={this.sendDataToRHub}
                              />
                              :
                              <Box className="postContainer">
                                {this.state.searchBar === true ?
                                  (
                                    <>
                                      <Paper className='search-details-box'>
                                        <SearchBlock
                                          handleSearchTabChange={this.handleSearchTabChange}
                                          isSearchType={this.state.isSearchType} state={this.state}
                                          onEditHandler={this.onEditHandler}
                                          playVideo={this.playVideo}
                                          onDeleteHandler={this.onDeleteHandler}
                                          onCommentChange={this.onCommentChange}
                                          onReplyHandler={this.onReplyHandler}
                                          onCommentHandler={this.onCommentHandler}
                                          onRepliedHandler={this.onRepliedHandler}
                                          playAudio={this.playAudio}
                                          handlePostDetails={this.handlePostDetails}
                                          onMediaModalHandler={this.onMediaModalHandler}
                                          onClickReplyComment={this.onClickReplyComment}
                                          onClickCommentIndex={this.onClickCommentIndex}
                                          handleConfirmModalOpen={this.handleConfirmModalOpen}
                                          allDownloadHandler={this.allDownloadHandler}
                                          commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
                                          onReportModalClickHandler={this.onReportModalClickHandler}
                                          getUserProfile={this.getUserProfile}
                                          onShareInputHandler={this.onShareInputHandler}
                                          onLikeHandler={this.onLikeHandler}
                                        />
                                      </Paper>
                                    </>
                                  ) :
                                  (
                                    <>
                                      <Box className="postcontainerCard">
                                        <div className="ellipse">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="87"
                                            height="87"
                                            viewBox="0 0 87 87"
                                            fill="none"
                                          >
                                            <path
                                              d="M1.0315e-06 0C11.3593 1.35459e-07 22.6075 2.23739 33.1021 6.58443C43.5968 10.9315 53.1325 17.303 61.1648 25.3353C69.197 33.3676 75.5686 42.9033 79.9156 53.3979C84.2626 63.8926 86.5 75.1407 86.5 86.5001L0 86.5L1.0315e-06 0Z"
                                              fill="#FEF3C7" />
                                          </svg>
                                        </div>
                                        <div className="goodMorningBox">
                                          <Typography className="textSecond">
                                            {t('Hey! Good Morning,')}
                                          </Typography>
                                          <Typography className="morningTitle">
                                            {t(`"Join the ultimate hangout spot and chat your heart out at our discussion forum platform!"`)}
                                          </Typography>
                                        </div>
                                        <Box className="selfieContainer"><img className="selfieContainer" src={selfie} /></Box>
                                      </Box>
                                      <Box className="introcard">
                                        <HashTag hashtagsData={this.state.hashtagsData} handleHashTags={this.handleHashTags} />
                                      </Box>
                                      <Box data-test-id="postModalHandlers" className="usercards" style={{cursor:"pointer"}} onClick={this.postModalHandler}>
                                      <Box className="usercardlefts">
                                        <Avatar className="input_logo" src={this.state.userDetails.photo} />
                                        <Box className="inputFields_container">
                                          <Typography className="inputClick">{t("Hey, share what's on your mind...")}</Typography>
                                        </Box>
                                      </Box>
                                      <Box className="attachment_container">
                                        <div className="svg_md">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M19.4632 5.57598C18.7752 4.82598 17.5342 4.77998 16.7072 5.60698L8.60718 13.707C8.39718 13.917 8.39718 14.183 8.60718 14.393C8.81718 14.603 9.08318 14.603 9.29318 14.393L15.9932 7.69298C16.1818 7.51082 16.4344 7.41003 16.6966 7.41231C16.9588 7.41459 17.2096 7.51976 17.395 7.70516C17.5804 7.89057 17.6856 8.14139 17.6879 8.40358C17.6901 8.66578 17.5893 8.91838 17.4072 9.10698L10.7072 15.807C10.4788 16.0419 10.2057 16.2287 9.90391 16.3563C9.60212 16.4838 9.27782 16.5495 8.95018 16.5495C8.62255 16.5495 8.29824 16.4838 7.99645 16.3563C7.69466 16.2287 7.42152 16.0419 7.19318 15.807C6.95822 15.5786 6.77145 15.3055 6.64391 15.0037C6.51637 14.7019 6.45065 14.3776 6.45065 14.05C6.45065 13.7223 6.51637 13.398 6.64391 13.0963C6.77145 12.7945 6.95822 12.5213 7.19318 12.293L15.2932 4.19298C16.8602 2.62498 19.4082 2.57398 20.9232 4.20798C22.4752 5.77698 22.5202 8.31198 20.8932 9.82098L11.4072 19.307C9.21718 21.497 5.78318 21.497 3.59318 19.307C1.40318 17.117 1.40318 13.683 3.59318 11.493L11.6932 3.39298C11.8818 3.21082 12.1344 3.11003 12.3966 3.11231C12.6588 3.11459 12.9096 3.21976 13.095 3.40516C13.2804 3.59057 13.3856 3.84138 13.3879 4.10358C13.3901 4.36578 13.2893 4.61838 13.1072 4.80698L5.00718 12.907C3.59718 14.317 3.59718 16.483 5.00718 17.893C6.41718 19.303 8.58318 19.303 9.99318 17.893L19.4932 8.39298L19.5242 8.36298C20.2742 7.67598 20.3202 6.43398 19.4932 5.60698C19.483 5.59685 19.473 5.58652 19.4632 5.57598Z"
                                              fill="#94A3B8" />
                                          </svg>
                                        </div>

                                        <div className="svg_xs">
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.9758 3.71753C12.5171 3.21753 11.6898 3.18686 11.1384 3.73819L5.73845 9.13819C5.59845 9.27819 5.59845 9.45552 5.73845 9.59552C5.87845 9.73552 6.05578 9.73552 6.19578 9.59552L10.6624 5.12886C10.7882 5.00742 10.9566 4.94022 11.1314 4.94174C11.3062 4.94326 11.4734 5.01337 11.597 5.13698C11.7206 5.26059 11.7907 5.42779 11.7922 5.60259C11.7937 5.77739 11.7266 5.94579 11.6051 6.07153L7.13845 10.5382C6.98622 10.6948 6.80413 10.8193 6.60293 10.9044C6.40174 10.9894 6.18554 11.0332 5.96711 11.0332C5.74869 11.0332 5.53249 10.9894 5.33129 10.9044C5.1301 10.8193 4.94801 10.6948 4.79578 10.5382C4.63914 10.386 4.51462 10.2039 4.4296 10.0027C4.34457 9.80148 4.30076 9.58528 4.30076 9.36686C4.30076 9.14844 4.34457 8.93223 4.4296 8.73104C4.51462 8.52985 4.63914 8.34775 4.79578 8.19553L10.1958 2.79553C11.2404 1.75019 12.9391 1.71619 13.9491 2.80553C14.9838 3.85153 15.0138 5.54153 13.9291 6.54752L7.60511 12.8715C6.14511 14.3315 3.85578 14.3315 2.39578 12.8715C0.935781 11.4115 0.935781 9.12219 2.39578 7.66219L7.79578 2.26219C7.92152 2.14075 8.08992 2.07356 8.26472 2.07508C8.43951 2.07659 8.60672 2.14671 8.73033 2.27031C8.85393 2.39392 8.92405 2.56113 8.92556 2.73592C8.92708 2.91072 8.85989 3.07912 8.73845 3.20486L3.33845 8.60486C2.39845 9.54486 2.39845 10.9889 3.33845 11.9289C4.27845 12.8689 5.72245 12.8689 6.66245 11.9289L12.9958 5.59552L13.0164 5.57553C13.5164 5.11753 13.5471 4.28953 12.9958 3.73819C12.989 3.73144 12.9823 3.72455 12.9758 3.71753Z" fill="#94A3B8" />
                                          </svg>
                                        </div>

                                        <Typography className='attachment_text'>{t("Attachment")}</Typography>
                                      </Box>
                                    </Box>
                                      <Box className="recentCardTitle">
                                        <Typography className="recentText">
                                          {t('Recent Forum Activity')}
                                        </Typography>
                                      </Box>
                                      <AllPostDetails
                                        state={this.state}
                                        authorId={this.state.userDetails.id}
                                        playVideo={this.playVideo}
                                        playAudio={this.playAudio}
                                        onEditHandler={this.onEditHandler}
                                        onReplyHandler={this.onReplyHandler}
                                        onDeleteHandler={this.onDeleteHandler}
                                        onCommentChange={this.onCommentChange}
                                        onCommentHandler={this.onCommentHandler}
                                        onRepliedHandler={this.onRepliedHandler}
                                        handlePostDetails={this.handlePostDetails}
                                        allDownloadHandler={this.allDownloadHandler}
                                        onClickReplyComment={this.onClickReplyComment}
                                        onClickCommentIndex={this.onClickCommentIndex}
                                        onMediaModalHandler={this.onMediaModalHandler}
                                        handleConfirmModalOpen={this.handleConfirmModalOpen}
                                        onCommentDeleteHandler={this.onCommentDeleteHandler}
                                        handleTranslation={this.handleTranslation}
                                        handleTransBackToOriginal={this.handleTransBackToOriginal}
                                        onReportModalClickHandler={this.onReportModalClickHandler}
                                        commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
                                        onShareInputHandler={this.onShareInputHandler}
                                        onLikeHandler={this.onLikeHandler}
                                        onrhDescriptionChange={this.onrhDescriptionChange}
                                        onrhLanguageChange={this.onrhLanguageChange}
                                        handleOnShareToResourceHub={this.sendDataToRHub}
                                      />
                                    </>

                                  )
                                }
                                {
                                  this.state.isPostLoading &&
                                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <CircularProgress color="inherit" />
                                  </Box>
                                }
                              </Box>
                          }
                        </Grid>

                      

                      <Modal
                        open={this.state.isOpen}
                        onClose={this.postModalHandler}
                        disableScrollLock={true}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ borderRadius: "10px", boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)", }}
                      >
                        <>
                          <Box style={style} className='modal-main'>
                            <Box className='modal-title'>
                              <h3>
                                {t('Create a Post')}
                              </h3>
                              <div className='modal-attachment'>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  onClick={this.handleAttachModal}
                                >
                                  <path
                                    d="M19.4632 5.57598C18.7752 4.82598 17.5342 4.77998 16.7072 5.60698L8.60718 13.707C8.39718 13.917 8.39718 14.183 8.60718 14.393C8.81718 14.603 9.08318 14.603 9.29318 14.393L15.9932 7.69298C16.1818 7.51082 16.4344 7.41003 16.6966 7.41231C16.9588 7.41459 17.2096 7.51976 17.395 7.70516C17.5804 7.89057 17.6856 8.14139 17.6879 8.40358C17.6901 8.66578 17.5893 8.91838 17.4072 9.10698L10.7072 15.807C10.4788 16.0419 10.2057 16.2287 9.90391 16.3563C9.60212 16.4838 9.27782 16.5495 8.95018 16.5495C8.62255 16.5495 8.29824 16.4838 7.99645 16.3563C7.69466 16.2287 7.42152 16.0419 7.19318 15.807C6.95822 15.5786 6.77145 15.3055 6.64391 15.0037C6.51637 14.7019 6.45065 14.3776 6.45065 14.05C6.45065 13.7223 6.51637 13.398 6.64391 13.0963C6.77145 12.7945 6.95822 12.5213 7.19318 12.293L15.2932 4.19298C16.8602 2.62498 19.4082 2.57398 20.9232 4.20798C22.4752 5.77698 22.5202 8.31198 20.8932 9.82098L11.4072 19.307C9.21718 21.497 5.78318 21.497 3.59318 19.307C1.40318 17.117 1.40318 13.683 3.59318 11.493L11.6932 3.39298C11.8818 3.21082 12.1344 3.11003 12.3966 3.11231C12.6588 3.11459 12.9096 3.21976 13.095 3.40516C13.2804 3.59057 13.3856 3.84138 13.3879 4.10358C13.3901 4.36578 13.2893 4.61838 13.1072 4.80698L5.00718 12.907C3.59718 14.317 3.59718 16.483 5.00718 17.893C6.41718 19.303 8.58318 19.303 9.99318 17.893L19.4932 8.39298L19.5242 8.36298C20.2742 7.67598 20.3202 6.43398 19.4932 5.60698C19.483 5.59685 19.473 5.58652 19.4632 5.57598Z"
                                    fill="#94A3B8" />
                                </svg>
                                <Typography className='descText' onClick={this.handleAttachModal}>{t('Attachment')}</Typography>
                              </div>
                              {this.state.isAttachOpen &&
                                <div style={{
                                  display: "flex",
                                  width: "96px",
                                  padding: "0 8px",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  gap: "8px",
                                  borderRadius: "4px",
                                  background: "#FFF",
                                  boxShadow: "0px 2px 3px 3px rgba(0, 0, 0, 0.08)",
                                  position: "absolute",
                                  top: '50px',
                                  right: '5px',
                                  zIndex: 9,
                                  cursor: "pointer"
                                }}>
                                  <MenuItem className="filterformMenu">
                                    <input data-test-id="imageInput" name="images" type='file' accept="image/png, image/jpeg, image/jpg" style={{ opacity: 0, zIndex: 9 }} multiple onChange={(e) => this.postMediaHandler(e, 'image')} />
                                    <span
                                      style={{ position: 'absolute', display: "flex", gap: "5px", cursor: "pointer" }}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M12.6667 3.33333V12.6667H3.33333V3.33333H12.6667ZM12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM9.42667 7.90667L7.42667 10.4867L6 8.76L4 11.3333H12L9.42667 7.90667Z" fill="#475569" />
                                      </svg>
                                      {t('Image')}
                                    </span>
                                  </MenuItem>
                                  <MenuItem className="filterformMenu">
                                    <input name="videos" type='file' accept="video/mp4, video/mov, video/wmv, video/flv, video/avi, video/mkv, video/webm" style={{ opacity: 0, zIndex: 9 }} multiple onChange={(e) => this.postMediaHandler(e, 'video')} />
                                    <span style={{ position: 'absolute', display: "flex", gap: "5px" }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M12.0002 6.98669V4.00002C12.0002 3.26669 11.4002 2.66669 10.6668 2.66669H2.66683C1.9335 2.66669 1.3335 3.26669 1.3335 4.00002V12C1.3335 12.7334 1.9335 13.3334 2.66683 13.3334H10.6668C11.4002 13.3334 12.0002 12.7334 12.0002 12V9.01335L14.6668 11.6667V4.33335L12.0002 6.98669ZM10.6668 12H2.66683V4.00002H10.6668V12ZM7.74683 7.66669L6.00016 10L4.92016 8.55335L3.3335 10.6667H10.0002L7.74683 7.66669Z" fill="#475569" />
                                      </svg>
                                      {t('Video')}
                                    </span>
                                  </MenuItem>
                                  <MenuItem className="filterformMenu">
                                    <input name="audios" type='file' accept="audio/mp3, audio/mpeg" style={{ opacity: 0, zIndex: 9 }} multiple onChange={(e) => this.postMediaHandler(e, 'audio')} />
                                    <span style={{ position: 'absolute', display: "flex", gap: "5px" }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M7.16651 12.6667C7.58873 12.6667 7.94428 12.5223 8.23317 12.2334C8.52206 11.9445 8.66651 11.5889 8.66651 11.1667V8.66671H10.6665V7.33338H7.99984V9.91671C7.87762 9.82782 7.74695 9.76382 7.60784 9.72471C7.46873 9.6856 7.32162 9.66626 7.16651 9.66671C6.74428 9.66671 6.38873 9.81115 6.09984 10.1C5.81095 10.3889 5.66651 10.7445 5.66651 11.1667C5.66651 11.5889 5.81095 11.9445 6.09984 12.2334C6.38873 12.5223 6.74428 12.6667 7.16651 12.6667ZM3.99984 14.6667C3.63317 14.6667 3.31917 14.536 3.05784 14.2747C2.7965 14.0134 2.66606 13.6996 2.66651 13.3334V2.66671C2.66651 2.30004 2.79717 1.98604 3.0585 1.72471C3.31984 1.46338 3.63362 1.33293 3.99984 1.33338H9.33317L13.3332 5.33338V13.3334C13.3332 13.7 13.2025 14.014 12.9412 14.2754C12.6798 14.5367 12.3661 14.6672 11.9998 14.6667H3.99984ZM8.66651 6.00004V2.66671H3.99984V13.3334H11.9998V6.00004H8.66651Z" fill="#475569" />
                                      </svg>
                                      {t('Audio')}
                                    </span>
                                  </MenuItem>
                                  <MenuItem className="filterformMenu">
                                    <input name="doc" accept="application/pdf, application/doc, application/docx, application/csv, application/msword" type='file' style={{ opacity: 0, zIndex: 9 }} multiple onChange={(e) => this.postMediaHandler(e, 'document')} />
                                    <span style={{ position: 'absolute', display: "flex", gap: "5px" }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M12.9753 3.71728C12.5166 3.21728 11.6893 3.18661 11.138 3.73795L5.73796 9.13795C5.59796 9.27795 5.59796 9.45528 5.73796 9.59528C5.87796 9.73528 6.05529 9.73528 6.19529 9.59528L10.662 5.12861C10.7877 5.00718 10.9561 4.93998 11.1309 4.9415C11.3057 4.94302 11.4729 5.01313 11.5965 5.13674C11.7201 5.26034 11.7902 5.42755 11.7917 5.60235C11.7933 5.77714 11.7261 5.94555 11.6046 6.07128L7.13796 10.5379C6.98573 10.6946 6.80364 10.8191 6.60245 10.9041C6.40125 10.9892 6.18505 11.033 5.96663 11.033C5.7482 11.033 5.532 10.9892 5.33081 10.9041C5.12961 10.8191 4.94752 10.6946 4.79529 10.5379C4.63865 10.3857 4.51414 10.2036 4.42911 10.0024C4.34408 9.80124 4.30027 9.58504 4.30027 9.36661C4.30027 9.14819 4.34408 8.93199 4.42911 8.73079C4.51414 8.5296 4.63865 8.34751 4.79529 8.19528L10.1953 2.79528C11.24 1.74995 12.9386 1.71595 13.9486 2.80528C14.9833 3.85128 15.0133 5.54128 13.9286 6.54728L7.60463 12.8713C6.14463 14.3313 3.85529 14.3313 2.39529 12.8713C0.935293 11.4113 0.935293 9.12195 2.39529 7.66195L7.79529 2.26195C7.92103 2.14051 8.08943 2.07331 8.26423 2.07483C8.43902 2.07635 8.60623 2.14646 8.72984 2.27007C8.85344 2.39367 8.92356 2.56088 8.92508 2.73568C8.92659 2.91048 8.8594 3.07888 8.73796 3.20461L3.33796 8.60461C2.39796 9.54461 2.39796 10.9886 3.33796 11.9286C4.27796 12.8686 5.72196 12.8686 6.66196 11.9286L12.9953 5.59528L13.016 5.57528C13.516 5.11728 13.5466 4.28928 12.9953 3.73795C12.9885 3.7312 12.9818 3.72431 12.9753 3.71728Z" fill="#475569" />
                                      </svg>
                                      {t('Document')}
                                    </span>
                                  </MenuItem>
                                </div>
                              }
                            </Box>
                            <Box className='modal-body' onClick={this.handleCloseAttachmentModal}>
                              <Box className='profile-wrap'>
                                <Avatar src={this.state.userDetails.photo} />
                                <span className="attachmentText">{t("Hey, share what's on your mind...")}</span>
                              </Box>
                              <Divider />
                              <Box className='input-box'>
                                <TextField className='input attachmentText'
                                  test-id="post-input"
                                  placeholder={t("Write description here")}
                                  value={this.state.postInput}
                                  onChange={this.postInputHandler}
                                  multiline
                                  minRows={4}
                                  maxRows={4}
                                  InputProps={{
                                    inputProps: {
                                        maxLength: 3000,
                                    }
                                }}
                                />
                              </Box>
                              <Grid container className="post_forum_counter_container">
                                    <Typography variant="h6" className='post_forum_counter_text'>
                                        {`${3000 - this.state.postInput.length}`}
                                    </Typography>
                                </Grid>
                            </Box>
                            <ModalContainer state={this.state} onFilteredHandler={this.onFilteredHandler} onExistedMediaHandler={this.onExistedMediaHandler} />

                            <Box className='modal-footer' onClick={this.handleCloseAttachmentModal}>
                              <Grid container justifyContent="space-between">
                                <Grid style={{ margin: "auto", overflowY: "scroll", maxHeight: "100px" }} item md={10} sm={10} lg={10} xs={12}>
                                  <Multiselect
                                    selectedValues={this.state.existedInterest}
                                    displayValue="key"
                                    groupBy="cat"
                                    placeholder={t("Select interest")}
                                    style={{ cursor: "pointer" }}
                                    onRemove={this.postInterestRemove}
                                    onSelect={this.postInterestHandler}
                                    options={this.state.userInterest}
                                    className="custom-multiselect"
                                    showCheckbox
                                  />
                                </Grid>
                                <Grid item className="web-post-btn" xs={12} sm={2}>
                                  <PostButton state={this.state} postApiHandler={this.postApiHandler} onEditApiHandler={this.onEditApiHandler} />.
                                </Grid>
                              </Grid>
                            </Box>
                            <Box className='modal-footer-responsive' onClick={this.handleCloseAttachmentModal}>
                              <Grid container justifyContent="space-between" spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <PostButton state={this.state} postApiHandler={this.postApiHandler} onEditApiHandler={this.onEditApiHandler} />
                                </Grid>
                              </Grid>
                            </Box>

                          </Box>
                          <Snackbar
                            style={{ zIndex: 9999 }}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={this.state.showModalSnackbar}
                            autoHideDuration={5000}
                            onClose={this.handleCloseModalSnackbar}
                          >
                            <MuiAlert
                              onClose={this.handleCloseModalSnackbar}
                              severity={this.state.severity}
                            >
                              {this.state.message}
                            </MuiAlert>
                          </Snackbar>
                          <Backdrop className="backdrop-loader" open={this.state.isLoading} >
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        </>
                      </Modal >
                      <ConfirmationBox
                        open={this.state.confirmModalOpen}
                        handleClose={this.handleConfirmModalClose}
                        handleSubmit={this.handleConfirmModalSubmit}
                      />
                      <Portal>
                        <Snackbar
                          style={{ zIndex: 9999 }}
                          anchorOrigin={{ vertical: "top", horizontal: "right" }}
                          open={this.state.showSnackbar}
                          autoHideDuration={5000}
                          onClose={this.handleCloseSnackbar}
                        >
                          <MuiAlert
                            onClose={this.handleCloseSnackbar}
                            severity={this.state.severity}
                          >
                            {this.state.message}
                          </MuiAlert>
                        </Snackbar>
                      </Portal>
                    </div>
                }
              </>}

          </>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End

import React from "react";
import {
    Grid,
    Button,
    Input,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {t} from "i18next";

const HandleAddMoreView = ({ list, handleAddFormFields, handleRemoveAddMoreFields,handleOtherFields, category }: any) => {
    return (
        <>
            {list && list.map((item: any, index: any) => (
                <Grid key={index} container className="other-fields">
                    <Grid item xs={6}>
                        <Input
                            value={item.name}
                            type={'text'}
                            placeholder={t('Type your interest here')}
                            className="add-more-text-box"
                            disableUnderline
                            name={'name'}
                            onChange={(e)=> handleOtherFields(e,category,index)}
                            inputProps={{
                                maxLength: 42
                            }}
                        />
                        <Input
                            disabled={true}
                            value={category}
                            type={'hidden'}
                            name={'interest_category'}
                        />
                    </Grid>
                    {index === 0 ?
                    <Grid item xs={6} className="add-more-btn-div">
                        <Button onClick={() => handleAddFormFields(category)} className="add-more-btn" variant="contained" color="secondary">{t('Add another interest')}</Button>
                    </Grid>:
                    <Grid item xs={6} className="add-more-btn-div">
                    <CloseIcon onClick={() => handleRemoveAddMoreFields(index,category)} className="cross-btn"/>
                    </Grid>
                    }
                </Grid>
            ))}
        </>
    );
}
export default HandleAddMoreView;

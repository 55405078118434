import React from "react";
import {
    Box,
    Avatar,
    Typography,
  } from "@material-ui/core";
import { groupIcons } from '../assets';
import { t } from "i18next";

export const AttachmentCard = ({postModalHandlers, group}:any) => {
  return(
    <>
    <Box data-test-id="postModalHandlers" className="usercards" style={{cursor:"pointer"}} onClick={() => postModalHandlers()}>
    <Box className="usercardlefts">
      <Avatar className="input_logo" src={group && group.attributes && group.attributes.icon_image || groupIcons} />
      <Box className="inputFields_container">
          <Typography className="inputClick">{t("Hey, share what's on your mind...")}</Typography>
      </Box>
    </Box>
        <Box className="attachment_container">
          <div className="svg_md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.4632 5.57598C18.7752 4.82598 17.5342 4.77998 16.7072 5.60698L8.60718 13.707C8.39718 13.917 8.39718 14.183 8.60718 14.393C8.81718 14.603 9.08318 14.603 9.29318 14.393L15.9932 7.69298C16.1818 7.51082 16.4344 7.41003 16.6966 7.41231C16.9588 7.41459 17.2096 7.51976 17.395 7.70516C17.5804 7.89057 17.6856 8.14139 17.6879 8.40358C17.6901 8.66578 17.5893 8.91838 17.4072 9.10698L10.7072 15.807C10.4788 16.0419 10.2057 16.2287 9.90391 16.3563C9.60212 16.4838 9.27782 16.5495 8.95018 16.5495C8.62255 16.5495 8.29824 16.4838 7.99645 16.3563C7.69466 16.2287 7.42152 16.0419 7.19318 15.807C6.95822 15.5786 6.77145 15.3055 6.64391 15.0037C6.51637 14.7019 6.45065 14.3776 6.45065 14.05C6.45065 13.7223 6.51637 13.398 6.64391 13.0963C6.77145 12.7945 6.95822 12.5213 7.19318 12.293L15.2932 4.19298C16.8602 2.62498 19.4082 2.57398 20.9232 4.20798C22.4752 5.77698 22.5202 8.31198 20.8932 9.82098L11.4072 19.307C9.21718 21.497 5.78318 21.497 3.59318 19.307C1.40318 17.117 1.40318 13.683 3.59318 11.493L11.6932 3.39298C11.8818 3.21082 12.1344 3.11003 12.3966 3.11231C12.6588 3.11459 12.9096 3.21976 13.095 3.40516C13.2804 3.59057 13.3856 3.84138 13.3879 4.10358C13.3901 4.36578 13.2893 4.61838 13.1072 4.80698L5.00718 12.907C3.59718 14.317 3.59718 16.483 5.00718 17.893C6.41718 19.303 8.58318 19.303 9.99318 17.893L19.4932 8.39298L19.5242 8.36298C20.2742 7.67598 20.3202 6.43398 19.4932 5.60698C19.483 5.59685 19.473 5.58652 19.4632 5.57598Z"
                fill="#94A3B8" />
            </svg>
          </div>

          <div className="svg_xs">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9758 3.71753C12.5171 3.21753 11.6898 3.18686 11.1384 3.73819L5.73845 9.13819C5.59845 9.27819 5.59845 9.45552 5.73845 9.59552C5.87845 9.73552 6.05578 9.73552 6.19578 9.59552L10.6624 5.12886C10.7882 5.00742 10.9566 4.94022 11.1314 4.94174C11.3062 4.94326 11.4734 5.01337 11.597 5.13698C11.7206 5.26059 11.7907 5.42779 11.7922 5.60259C11.7937 5.77739 11.7266 5.94579 11.6051 6.07153L7.13845 10.5382C6.98622 10.6948 6.80413 10.8193 6.60293 10.9044C6.40174 10.9894 6.18554 11.0332 5.96711 11.0332C5.74869 11.0332 5.53249 10.9894 5.33129 10.9044C5.1301 10.8193 4.94801 10.6948 4.79578 10.5382C4.63914 10.386 4.51462 10.2039 4.4296 10.0027C4.34457 9.80148 4.30076 9.58528 4.30076 9.36686C4.30076 9.14844 4.34457 8.93223 4.4296 8.73104C4.51462 8.52985 4.63914 8.34775 4.79578 8.19553L10.1958 2.79553C11.2404 1.75019 12.9391 1.71619 13.9491 2.80553C14.9838 3.85153 15.0138 5.54153 13.9291 6.54752L7.60511 12.8715C6.14511 14.3315 3.85578 14.3315 2.39578 12.8715C0.935781 11.4115 0.935781 9.12219 2.39578 7.66219L7.79578 2.26219C7.92152 2.14075 8.08992 2.07356 8.26472 2.07508C8.43951 2.07659 8.60672 2.14671 8.73033 2.27031C8.85393 2.39392 8.92405 2.56113 8.92556 2.73592C8.92708 2.91072 8.85989 3.07912 8.73845 3.20486L3.33845 8.60486C2.39845 9.54486 2.39845 10.9889 3.33845 11.9289C4.27845 12.8689 5.72245 12.8689 6.66245 11.9289L12.9958 5.59552L13.0164 5.57553C13.5164 5.11753 13.5471 4.28953 12.9958 3.73819C12.989 3.73144 12.9823 3.72455 12.9758 3.71753Z" fill="#94A3B8" />
            </svg>
          </div>

          <Typography className='attachment_text'>{t("Attachment")}</Typography>
        </Box>
  </Box>
  </>
  )
}  
import React from "react";
import { Button, Grid, Box, Typography, Badge, Avatar } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import { t } from "i18next";
import MemberDirectoryController, { Props } from '../../src/MemberDirectoryController'

class MemberDirectoryList extends MemberDirectoryController {
    constructor(props: Props) {
        super(props);
    }

    renderSkills = (skillsObject: any, index: number) => {
        const { showAll } = this.state;
        const displayeSkills = showAll[index]
            ? skillsObject.attributes.skill_name
            : skillsObject.attributes.skill_name?.slice(0, 10);
        const remainingCount = skillsObject.attributes.skill_name?.length - displayeSkills?.length;

        return (
            <Grid container item className="skils_grid">
                <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                    {displayeSkills?.map((item: any, skillIndex: number) => (
                        <div key={skillIndex} style={{ marginInline: "5px", width: "max-content" }}>
                            <Button className="skills_button" variant="contained">
                                <div className="tag-interest">{item.name}</div>
                            </Button>
                        </div>
                    ))}
                    {remainingCount > 0 && !showAll[index] && (
                        <Button
                            variant="text"
                            onClick={(event) => {
                                event.stopPropagation();
                                this.toggleShowAll(index);
                            }}
                            className="md_show_less"
                        >
                            {t('+')}{remainingCount} {t('more')}
                        </Button>
                    )}
                    {showAll[index] && (
                        <Button
                            variant="text"
                            onClick={(event) => {
                                event.stopPropagation();
                                this.toggleShowAll(index);
                            }}
                            className="skills_show_less"
                        >
                            {t('Show Less')}
                        </Button>
                    )}
                </div>
            </Grid>
        );
    };

    render() {
        return (
            <div>
                {this.props.membersData?.map((item: any, index: number) => (
                    <Box
                        key={index}
                        className="member_card_box"
                        data-test-id={`member_card ${index}`}
                        onClick={() => this.navigateToMemberDetail(item.id)}
                    >
                        <Grid container spacing={1} className="member_card">
                            <Grid item container xs={12} sm={6} className="member_main_info">
                                <Grid container spacing={0} className="member_intro">
                                    <Grid className="member_avatar">
                                        <Avatar
                                            src={item.attributes.photo}
                                            alt="User Profile Picture"
                                            style={{ width: 56, height: 56 }}
                                        />
                                    </Grid>
                                    <Grid className="member_name">
                                        <Typography variant="subtitle2" className="md_name">
                                            {t(item.attributes.full_name)}
                                        </Typography>
                                        <Grid item className="md_location_info">
                                            <Badge style={{ marginTop: '2px' }}>
                                                <PlaceOutlinedIcon
                                                    style={{ color: '#475569', fontSize: 16 }}
                                                />
                                            </Badge>
                                            <Typography variant="subtitle2" className="md_location_text">
                                            {t(item.attributes?.city)}, {t(item.attributes?.state)}, {t(item.attributes.country)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '16px' }}>
                                    <Typography variant="subtitle2" className="mission_title">
                                        {t("Mission / About")}
                                    </Typography>
                                    <Box className="mission_box">
                                        <FiberManualRecordIcon
                                            fontSize="small"
                                            style={{
                                                color: '#475569',
                                                fontSize: "10px",
                                                marginRight: "8px",
                                                marginTop: '4px'
                                            }}
                                        />
                                        <Typography className="mission_description">
                                            {t(item.attributes.about_yourself)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid style={{ marginTop: '16px' }}>
                                    <Typography variant="subtitle2" className="mission_title">
                                        {t("Organizational affiliations and Socials")}
                                    </Typography>
                                    {typeof item.attributes.organizational_affilation === 'string' && <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box className="oas_box">
                                                <div data-test-id= "mission-desc" className="mission_description" dangerouslySetInnerHTML={{__html: item.attributes.organizational_affilation}}></div>
                                            </Box>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            <Grid sm={1}></Grid>
                            <Grid item container xs={12} sm={5} className="member_secondary_info">
                                <Typography variant="subtitle2" className="skills_text">
                                    {t("Skills")}
                                </Typography>
                                {this.renderSkills(item, index)}
                            </Grid>
                        </Grid>
                    </Box>
                ))}
                {
                    (this.props.membersData?.length === 0) && (
                        <Box
                            borderTop={'1px solid #ccc'}
                            marginTop={"16px"}
                            paddingTop={'16px'}
                        >
                            <Typography style={{ color: "#64748B", fontWeight: 400, textAlign: "center" }}>No Result Found</Typography>
                        </Box>
                    )
                }
            </div>
        );
    }
}

export default MemberDirectoryList;

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { t } from "i18next";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";
export interface OtherInterestFormField {
  name: string,
  interest_category: string,
  created_by_admin: boolean
}
const otherInterestAddMoreField: OtherInterestFormField = {
  name: "", interest_category: "", created_by_admin: false
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleOpen:any;
  data:any;
  openOtherComponent:any;
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  showSnackbars: boolean;
  severitys: any;
  messages: string;
  isLoading: boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ButtonController extends BlockComponent<
  Props,
  S,
  SS
>
    {
      // Customizable Area Start
      getInterestsApiCallId = "";
      postApiCallId = "";
      token = getAuthToken();
      mediaArr: any = []
      // Customizable Area End

  constructor(props: Props) {
    super(props);
this.subScribedMessages = [
  // Customizable Area Start
  getName(MessageEnum.RestAPIResponceMessage),
  getName(MessageEnum.SessionResponseMessage),
  // Customizable Area End
];

this.state = {
  // Customizable Area Start
  id: "",
  showSnackbars: false,
  severitys:"",
  messages:"",
  isLoading:false
  // Customizable Area End
};

runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

// Customizable Area Start
this.receive = this.receive.bind(this);
// Customizable Area End

  }

  // Customizable Area Start
   receive = (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIds = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsons = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallIds === this.postApiCallId) {
          if (responseJsons && responseJsons.data) {
            this.setState({
              showSnackbars: true,
              severitys: "success",
              messages: t("Group created successfully"),
              isLoading:false
            });
            localStorage.removeItem("edit_group")
            localStorage.setItem("new_group", JSON.stringify(responseJsons.data))
            window.location.href = "AccountGroups"
          } else {
            this.setState({
              isLoading:false,
              showSnackbars: true,
              messages: responseJsons?.errors?.message || t('something went wrong'),
              severitys: "error",
            });
          }
      }
}

  }

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbars: false,
      severitys: "",
      messages: "",
    });
    return true;
  };

  checkUniqueInterest = (otherInterestFields:any, interestsCategories:any) => {
    let valueArr = otherInterestFields.map(function (item:any) { return item.name });
    const isDuplicate = valueArr.some(function (item:any, idx:any) {
      return valueArr.indexOf(item) != idx
    });
    if (isDuplicate) {
      return true;
    }
    let unique = false;
    otherInterestFields.forEach((o1: any) => {
      const check = interestsCategories.filter((o2: any) => (o1.name === o2.attributes.name));
      if (check.length) {
        unique = true;
      }
    });
    if (unique) {
      return true
    }
    return false;
  }

  handleGroupInterestsAttributes = (selected_interest:any, selectedInterestIds:any, otherInterestImpactFields:any, showOtherImpactFields:any,
                                    otherInterestSkillsFields:any, showOtherSkillsFields:any, edit_group:any ) => {
    let group_interests_attributes = edit_group ? [...selectedInterestIds] : [...selected_interest];
    if (otherInterestImpactFields.length && showOtherImpactFields === true) {
      const otherImpact = otherInterestImpactFields.map((item: any) => {
        return { interest_attributes: { ...item } }
      })
      group_interests_attributes = [...group_interests_attributes, ...otherImpact];
    }
    if (otherInterestSkillsFields.length && showOtherSkillsFields === true) {
      const otherSkills = otherInterestSkillsFields.map((item: any) => {
        return { interest_attributes: { ...item } }
      })
      group_interests_attributes = [...group_interests_attributes, ...otherSkills];
    }
    return group_interests_attributes
  }

  handleSubmit = (event:any, data:any) => {
    event && event.preventDefault()
    this.setState({isLoading:true})
    const {groupName, description, value, uploadCoverPhoto, uploadProfilePhoto, otherInterestImpactFields, interests, otherInterestSkillsFields,
    selected_interest, showOtherImpactFields, showOtherSkillsFields, iconPhotoKey, coverPhotoKey, removedInterestIds, selectedInterestIds
    } = data
    const edit_group = JSON.parse(localStorage.getItem("edit_group")!)
    if(!groupName || !description || !value){
       return this.setState({
            showSnackbars: true,
            messages: t('Group name, description and settings are required.'),
            severitys: "error",
            isLoading: false
          });
    }
        this.setState({
          showSnackbars: false,
          messages: '',
          severitys: "",
        });
      const  group_interests_attributes = this.handleGroupInterestsAttributes(selected_interest, selectedInterestIds, otherInterestImpactFields,
         showOtherImpactFields, otherInterestSkillsFields, showOtherSkillsFields, edit_group)
       let checkRes = this.checkUniqueInterest(otherInterestImpactFields, interests.impact_area);
       let checkRes2 = this.checkUniqueInterest(otherInterestSkillsFields, interests.skills_and_approaches);
       if(checkRes && checkRes2){
        return this.setState({
              showSnackbars: true,
              messages: t('Interest name should not be duplicate.'),
              severitys: "error",
            });
       }
      let finalData = { groupName, description, value, uploadCoverPhoto, uploadProfilePhoto, group_interests_attributes, iconPhotoKey, coverPhotoKey, 
        removedInterestIds } as any;
      delete finalData.selected_interest;
      this.handleCreateNewGroup(finalData, edit_group)
  };

  handleCreateNewGroup = (finalData: any, edit_group:any) => {
    const { groupName, description, value, uploadCoverPhoto, uploadProfilePhoto, group_interests_attributes, iconPhotoKey, coverPhotoKey, 
      removedInterestIds } = finalData
    let formData = new FormData();
    formData.append('data[attributes][name]', groupName.trim());
    formData.append('data[attributes][group_description]', description.trim());
    formData.append('data[attributes][group_type]', value);
    if(iconPhotoKey){
      formData.append('data[attributes][icon_image]', uploadProfilePhoto);
    }
    if(coverPhotoKey){
      formData.append('data[attributes][cover_image]', uploadCoverPhoto);
    }

  group_interests_attributes.forEach((i: any, index:number) => {
    if(Object.keys(i)[0] === "interest_id"){
       formData.append(`data[attributes][group_interests_attributes][${index}][interest_id]`, i.interest_id);
    } else{
      formData.append(`data[attributes][group_interests_attributes][${index}][interest_attributes][name]`,
      i.interest_attributes && i.interest_attributes.name)
      formData.append(`data[attributes][group_interests_attributes][${index}][interest_attributes][created_by_admin]`,
      i.interest_attributes && i.interest_attributes.created_by_admin)
      formData.append(`data[attributes][group_interests_attributes][${index}][interest_attributes][interest_category]`,
      i.interest_attributes && i.interest_attributes.interest_category)
    }
  })

  removedInterestIds && removedInterestIds.forEach((i: any, index:number) => {
    formData.append(`data[attributes][remove_interests][${index}]`, i);
  })

const header = {
  "token": this.token
};

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.postApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  edit_group ? `account_groups/groups/${edit_group.id}` : configJSON.postApiEndPoint
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  edit_group ? configJSON.putApiMethod : configJSON.postApiMethod
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formData
);

runEngine.sendMessage(requestMessage.id, requestMessage);
return true;

  }
  // Customizable Area End
}